import { func, shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { Content, Wrapper } from '../../styles';
import staticText from '../../../../localization/Setup/teamInvite';
import EmailInput, { useEmailInput } from '../../../Application/components/Members/components/EmailInput';

import { dashboardRole, getOrganizationRole } from '../../../Application/components/Members/utils/roles';
import { status } from '../../../../services/organizations/reducers';

const TeamInvite = ({
  actions: { sendOrgInvites, updateSendOrgInvitesStatus },
  sendOrgInvitesStatus,
  history,
}) => {
  const {
    emails,
    getEmailsToSend,
    handleEmailsChange,
    handleInputChange,
    handleKeyDown,
    inputError,
    inputValue,
  } = useEmailInput();

  const handleInvites = (event) => {
    event.preventDefault();
    const emailsToSend = getEmailsToSend();
    if (emailsToSend) {
      // const applicationIds = selectedApps;
      const formContent = {
        emailsToSend,
        // Invite other owners during sign up
        selectedRole: dashboardRole.OWNER,
        organizationRole: getOrganizationRole(dashboardRole.OWNER),
        applicationIds: [],
      };
      sendOrgInvites(formContent);
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: 'invite members',
        form_content: formContent,
      });
    }
  };

  const continueToInitialization = () => history.push('/');

  useEffect(() => {
    if (sendOrgInvitesStatus === status.SUCCESS) {
      updateSendOrgInvitesStatus(status.INACTIVE);
      continueToInitialization();
    } else if (sendOrgInvitesStatus === status.FAILURE) {
      updateSendOrgInvitesStatus(status.INACTIVE);
    }
  }, [sendOrgInvitesStatus]);

  useEffect(() => {
    reportToSegment(types.PAGE, 'Setup - Team Invite');
  }, []);

  return (
    <Content>
      <Wrapper>
        <Typography variant="h1" sx={{ textTransform: 'none' }} mb={2}>{staticText.title}</Typography>
        <Typography mb={4}>{staticText.subtitle}</Typography>
        <form onSubmit={handleInvites}>
          <EmailInput
            emails={emails}
            handleChange={handleEmailsChange}
            handleInputChange={handleInputChange}
            handleKeyDown={handleKeyDown}
            inputError={inputError}
            inputValue={inputValue}
          />
          <Box display="flex" gap={3} alignItems="center" my={2}>
            <Button
              id="submit-button"
              variant="contained"
              size="large"
              type="submit"
              // disabled={disableFields}
            >{staticText.buttons.sendInvite}
            </Button>
            <Button
              id="skip"
              variant="text"
              onClick={continueToInitialization}
            >{staticText.buttons.skip}
            </Button>
          </Box>
        </form>
      </Wrapper>
    </Content>
  );
};

export default TeamInvite;

TeamInvite.propTypes = {
  actions: shape({
    sendOrgInvites: func.isRequired,
    updateSendOrgInvitesStatus: func.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  sendOrgInvitesStatus: string.isRequired,
};
