import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import {
  Button,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';

import { Chip, PermissionGate, Spinner } from '../../../../../../components';

import { EmptyStateContainer, EmptyStateImage } from '../../../../../../global-styles/components';
import {
  StyledNameCell,
  StyledIconButton,
  StyledMenuItem,
  StyledTableCell,
  AppList,
} from './styles';

import astronautComputer from '../../../../../../assets/images/astronaut-computer.png';
import staticText, { menuMap, tableHeaders } from '../../../../../../localization/Application/Members/membersTable';
import { modalType } from '../../../../../../localization/Application/Members/members';
import { organizationRole } from '../../utils/roles';

const MembersTable = ({
  sendOrgInvites,
  deleteOrgInvite,
  isLoading,
  rows,
  openModal,
}) => {
  if (isLoading) {
    return <Spinner />;
  }

  const theme = useTheme();
  const [menuOpenElement, setMenuOpenElement] = useState(null);

  const actionMap = {
    resendInvite: (invitation) => {
      sendOrgInvites({
        emailsToSend: [invitation.email],
        selectedRole: invitation.dashboardRole,
        organizationRole: invitation.organizationRole,
        applicationIds: invitation.applications.map(app => app.id),
      });
    },
    deleteInvite: (invite) => {
      deleteOrgInvite(invite.id);
    },
    sendInvites: () => openModal(modalType.sendInvites),
    editUser: user => openModal(modalType.editUser, { user }),
    deleteUser: user => openModal(modalType.deleteUser, { user }),
  };

  const handleClick = (e) => {
    setMenuOpenElement(e.currentTarget);
  };

  const handleClose = () => {
    setMenuOpenElement(null);
  };

  const getAccessText = (appCount, orgRole) => {
    if ([organizationRole.FULL_ACCESS, organizationRole.ALL_APPS].includes(orgRole)) {
      return 'All apps';
    }
    return `${appCount} App${appCount > 1 ? 's' : ''}`;
  };

  const emptyState = (
    <EmptyStateContainer>
      <EmptyStateImage src={astronautComputer} alt="astronaut sitting at a computer in space" />
      <Typography variant="h2" sx={{ mb: 4 }}>{staticText.emptyState.title}</Typography>
      <PermissionGate dashboardPermission="write_team">
        <Button
          id="empty-state-cta"
          variant="contained"
          size="large"
          onClick={actionMap[staticText.emptyState.buttonAction]}
        >
          {staticText.emptyState.buttonText}
        </Button>
      </PermissionGate>
    </EmptyStateContainer>
  );

  const renderCell = (column, row) => {
    const columnMap = {
      name: () => (
        <StyledNameCell key={`${row.id}-name+email`}>
          {row.name && <Typography>{row.name}</Typography>}
          <Typography>{row.email}</Typography>
        </StyledNameCell>
      ),
      access: () => {
        const memberApps = (
          <AppList>
            {row.applications.map(app => <li key={app.id}>{app.name}</li>)}
          </AppList>
        );
        return (
          <TableCell key={`${row.id}-access`}>
            <Tooltip title={memberApps} arrow placement="right">
              <Typography sx={{ textTransform: 'capitalize', width: 'fit-content' }}>
                {getAccessText(row.applications.length, row.organizationRole)}
              </Typography>
            </Tooltip>
          </TableCell>
        );
      },
      status: () => (
        <TableCell key={`${row.id}-status`}>
          {row.status && <Chip text={row.status} additionalStyle="status" />}
        </TableCell>
      ),
      moreActions: () => {
        const menuOpen = Boolean(menuOpenElement && menuOpenElement.id === row.id);
        let menuItems = menuMap[row.type];
        if (row.originalOwner === true) {
          menuItems = menuItems.filter(
            menuItem => !menuItem.excludeOwners,
          );
        }

        return menuItems.length > 0 ? (
          <TableCell key={`${row.id}-actions`} align="right">
            <PermissionGate dashboardPermission="write_team">
              <StyledIconButton id={row.id} isOpen={menuOpen} onClick={handleClick}>
                <MoreHorizRoundedIcon htmlColor={theme.palette.grey[600]} />
              </StyledIconButton>
              <Menu
                anchorEl={menuOpenElement}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={menuOpen}
                onClose={handleClose}
                sx={{ mt: 1 }}
                slotProps={{
                  paper: {
                    sx: { boxShadow: theme.border.boxShadow2, pl: 1, pr: 1 },
                  },
                }}
              >
                {menuItems.map((menuItem) => {
                  const menuItemClick = () => {
                    if (menuItem.action && actionMap[menuItem.action]) {
                      actionMap[menuItem.action](row);
                    }
                    handleClose();
                  };
                  return (
                    <StyledMenuItem
                      key={menuItem.displayName}
                      onClick={menuItemClick}
                    >
                      {menuItem.displayName}
                    </StyledMenuItem>
                  );
                })}
              </Menu>

            </PermissionGate>
          </TableCell>
        ) : <TableCell key={`${row.id}-actions`} />;
      },
      default: () => {
        return (
          <StyledTableCell key={`${row.id}-${row[column.key]}`} style={column.cellStyle}>
            {row[column.key]}
          </StyledTableCell>
        );
      },
    };

    return columnMap[column.key] ? columnMap[column.key]() : columnMap.default();
  };

  return (
    <TableContainer sx={{ marginTop: 1.5 }}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map(({ key, width, displayName }) => (
              <TableCell key={key} width={width}>
                <Typography variant="h3">{displayName}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {rows.length > 0 && (
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id} sx={{ height: '68px' }}>
                {tableHeaders.map(column => renderCell(column, row))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {rows.length === 0 && emptyState}
    </TableContainer>
  );
};

export default MembersTable;

MembersTable.propTypes = {
  sendOrgInvites: func.isRequired,
  deleteOrgInvite: func.isRequired,
  isLoading: bool.isRequired,
  rows: arrayOf(shape({
    id: string.isRequired,
    name: string,
    email: string,
    organizationRole: string.isRequired,
    dashboardRole: string.isRequired,
    status: string,
    applications: arrayOf(shape({
      id: string.isRequired,
      name: string.isRequired,
    })).isRequired,
  })).isRequired,
  openModal: func.isRequired,
};
