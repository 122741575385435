import React from 'react';
import { shape, string } from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ConnectCustomizations from '../ConnectCustomizations';
import ConnectInsights from '../ConnectInsights';

const Connect = ({ match }) => {
  const { applicationId } = match.params;
  const { connectInsights } = useFlags();

  return (
    <Switch>
      <Route
        path={`/apps/${applicationId}/connect/customizations`}
        component={ConnectCustomizations}
      />
      {connectInsights &&
        <Route
          path={`/apps/${applicationId}/connect/insights`}
          component={ConnectInsights}
        />
      }
      <Redirect to={`/apps/${applicationId}/connect/customizations`} />
    </Switch>
  );
};

export default Connect;

Connect.propTypes = {
  match: shape({
    params: shape({
      applicationId: string.isRequired,
    }).isRequired,
  }).isRequired,
};
