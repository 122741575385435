/* eslint-disable react/forbid-prop-types */
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import { Box, Button, Grid, Icon, Typography, useTheme } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { styled } from '@mui/styles';

import { filterIcon, filterIconDisabled } from '../../../../../../../../assets/icons';

import staticText from '../../../../../../../../localization/Application/connect-config';

import { Checkbox, PermissionGate, Spinner } from '../../../../../../../../components';
import FiltersModal from '../FiltersModal';
import FilterPillsList from './components/FilterPillsList';
import compareMakesAndAvailableFilters from '../../utils/compareMakesAndAvailableFilters';
import determineFilteredBrands from '../../utils/determineFilteredBrands';

const StyledButton = styled(Button)(() => ({
  alignItems: 'unset',
  padding: '5px 10px',
  marginBottom: '10px',
}));

const BrandList = ({
  applicationMakes,
  isPending,
  selectedMakes,
  disableAllBrands,
  areAllBrandsDisabled,
  updateSelectedMakes,
  updateAppliedFilters,
  updateAvailableMakesAfterAppliedFilter,
  fetchApplicationMakesRequest,
  fetchConnectConfigRequest,
  fetchBrandManagementFilterOptions,
  brandManagementFilterOptions,
  brandManagementCapabilities,
  selectedBrandManagementFilters,
  availableMakesAfterAppliedFilter,
  connectConfigError,
  applicationMakesError,
  renderErrorMessage,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [filterPills, setFilterPills] = useState(selectedBrandManagementFilters);
  const closeModal = () => setModalOpen(false);
  const theme = useTheme();

  const isFilterButtonDisabled = selectedBrandManagementFilters.length > 2;

  // If an user removes or adds a filter, but goes to a different page
  // rather than clicking the publish button, then this useEffect resets
  // the filters if they decide to go back to the brands tab. Without this useEffect,
  // the old unapplied filters will show unless you refresh a couple of times
  useEffect(() => {
    return () => {
      fetchApplicationMakesRequest();
      fetchConnectConfigRequest();
      fetchBrandManagementFilterOptions();
    };
  }, []);

  const handleChange = (event) => {
    let updatedCheckboxList = [...selectedMakes];

    if (event.target.checked) {
      updatedCheckboxList = [...selectedMakes, event.target.name];
    } else {
      updatedCheckboxList.splice(selectedMakes.indexOf(event.target.name), 1);
    }

    updateSelectedMakes(updatedCheckboxList);
  };

  const selectAll = () => {
    updateSelectedMakes(availableMakesAfterAppliedFilter);
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'action',
      text: `[brand management] ${staticText.brandManagement.selectAll}`,
    });
  };
  const clearAll = () => {
    updateSelectedMakes([]);
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'action',
      text: `[brand management] ${staticText.brandManagement.clearAll}`,
    });
  };

  const deleteSelectedFilter = (selectedFilter) => {
    const updatedFilterPills = filterPills.filter(item => item !== selectedFilter);
    setFilterPills(updatedFilterPills);
    updateAppliedFilters(updatedFilterPills);
    disableAllBrands(false);
  };

  const applyFilters = async (selectedFilterOptions) => {
    const {
      disableBrands,
      availableBrandsAfterAppliedFilter,
      engineTypeSelectedFilters,
      endpointSelectedFilters,
    } =
      await determineFilteredBrands(
        brandManagementCapabilities,
        selectedFilterOptions,
        selectedBrandManagementFilters,
      );

    updateAvailableMakesAfterAppliedFilter(availableBrandsAfterAppliedFilter);

    const updatedMakes =
      compareMakesAndAvailableFilters(selectedMakes, availableBrandsAfterAppliedFilter);


    if (filterPills.length < 3) {
      const updatedFilterPills = [...filterPills];

      if (engineTypeSelectedFilters.length > 0) updatedFilterPills.push(engineTypeSelectedFilters);
      if (endpointSelectedFilters.length > 0) updatedFilterPills.push(endpointSelectedFilters);
      setFilterPills(updatedFilterPills);
      updateAppliedFilters(updatedFilterPills);
    }

    updateSelectedMakes(disableBrands ? [] : updatedMakes);
    disableAllBrands(disableBrands);
    closeModal();
  };

  return applicationMakesError ? (
    renderErrorMessage(applicationMakesError)
  ) : (
    <React.Fragment>
      {
        modalOpen &&
        <FiltersModal
          applyFilters={applyFilters}
          setFilterPills={setFilterPills}
          filterPills={filterPills}
          brandManagementCapabilities={brandManagementCapabilities}
          brandManagementFilterOptions={brandManagementFilterOptions}
          selectedMakes={selectedMakes}
          updateSelectedMakes={updateSelectedMakes}
          updateAppliedFilters={updateAppliedFilters}
          updateAvailableMakesAfterAppliedFilter={updateAvailableMakesAfterAppliedFilter}
          closeModal={closeModal}
        />
      }
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginY={theme.spacing(2)}
      >
        <Typography variant="h3">{staticText.brandManagement.displayedBrands}</Typography>
        <PermissionGate dashboardPermission="write_connect_configuration">
          <div>
            <StyledButton
              id="select-all-button"
              variant="outlined"
              onClick={selectAll}
              disabled={isPending}
            >
              {staticText.selectAll}
            </StyledButton>
            <StyledButton
              id="clear-all-button"
              variant="outlined"
              onClick={clearAll}
              disabled={isPending}
              sx={{ marginLeft: theme.spacing(2) }}
            >
              {staticText.clearAll}
            </StyledButton>
          </div>
        </PermissionGate>
      </Box>
      {
        <Box sx={{ marginBottom: theme.spacing(3) }}>
          <Box display="flex" flexWrap="wrap">
            <Button
              startIcon={
                <Icon>
                  <img
                    src={
                      isFilterButtonDisabled ?
                      filterIconDisabled : filterIcon
                    }
                    alt="filter icon"
                  />
                </Icon>
                }
              id="filter-button"
              variant="outlined"
              sx={{
                  marginRight: '10px',
                  marginBottom: '10px',
                  alignItems: 'flex-start',
                }}
              onClick={() => setModalOpen(true)}
              disabled={isFilterButtonDisabled}
            >
              {staticText.filter}
            </Button>
            {
                filterPills &&
                  <FilterPillsList
                    filterPills={filterPills}
                    deleteSelectedFilter={deleteSelectedFilter}
                  />
              }
          </Box>
        </Box>
      }
      {connectConfigError && renderErrorMessage(connectConfigError)}
      <Box minHeight="360px">
        {applicationMakes.length === 0 && isPending &&
          <Box display="flex" justifyContent="center" pt={14}>
            <Spinner size="small" />
          </Box>
        }
        {applicationMakes.length > 0 &&
          <Grid container spacing={theme.spacing(2)}>
            {applicationMakes.map(applicationMake => (
              <Grid key={applicationMake.make} item xs={3}>
                <PermissionGate
                  dashboardPermission="write_connect_configuration"
                  disabled
                >
                  <Checkbox
                    inputName={applicationMake.make}
                    text={applicationMake.displayName}
                    handleChange={handleChange}
                    checked={selectedMakes.includes(applicationMake.make)}
                    disabled={
                      isPending || areAllBrandsDisabled ||
                      !availableMakesAfterAppliedFilter.includes(applicationMake.make)
                    }
                    icon={InfoOutlined}
                    disabledIcon={
                      areAllBrandsDisabled ||
                      !availableMakesAfterAppliedFilter.includes(applicationMake.make)
                    }
                    disabledIconText={
                      areAllBrandsDisabled ||
                      staticText.brandManagement.disabledIconText
                    }
                  />
                </PermissionGate>
              </Grid>
            ))}
          </Grid>
        }
      </Box>
    </React.Fragment>
  );
};

export default BrandList;

BrandList.propTypes = {
  applicationMakes: arrayOf(shape({
    make: string.isRequired,
    displayName: string.isRequired,
  })).isRequired,
  isPending: bool.isRequired,
  selectedMakes: arrayOf(string),
  updateSelectedMakes: func.isRequired,
  updateAppliedFilters: func.isRequired,
  updateAvailableMakesAfterAppliedFilter: func.isRequired,
  fetchApplicationMakesRequest: func.isRequired,
  fetchConnectConfigRequest: func.isRequired,
  fetchBrandManagementFilterOptions: func.isRequired,
  connectConfigError: string,
  applicationMakesError: string,
  renderErrorMessage: func.isRequired,
  availableMakesAfterAppliedFilter: arrayOf(string).isRequired,
  selectedBrandManagementFilters: arrayOf(arrayOf),
  brandManagementCapabilities: object.isRequired,
  brandManagementFilterOptions: shape({
    engineType: object.isRequired,
    endpoints: object.isRequired,
  }).isRequired,
  disableAllBrands: func.isRequired,
  areAllBrandsDisabled: bool.isRequired,
};

BrandList.defaultProps = {
  selectedMakes: [],
  selectedBrandManagementFilters: [],
  connectConfigError: '',
  applicationMakesError: '',
};
