import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import authFormState from '../../../../../../../Authentication/components/authFormUtils';
import staticText from '../../../../../../../../localization/Authentication/updateUser';
import { InputField, Spinner, Toast } from '../../../../../../../../components';
import {
  EmailVerificationButton,
  EmailVerificationContainer,
  EmailVerificationError,
  UpdateUserForm,
} from './styles';

const UpdateUser = ({
  actions: {
    forgotPasswordRequest,
    resetErrors,
    sendVerificationEmailRequest,
    updateUserRequest,
  },
  forgotPasswordSuccess,
  inProgress,
  isSendingVerificationEmail,
  sendVerificationEmailError,
  session: {
    userContext,
  },
  toggleModal,
  updateUserFormErrors,
  updateUserSuccess,
  verificationEmailSent,
}) => {
  const [disableFields, setDisableFields] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const {
    setErroredFields,
    erroredFields,
    handleBlur,
    handleChange,
    values,
    getErrorMessage,
  } = authFormState({
    firstName: userContext.firstName,
    lastName: userContext.lastName,
    username: userContext.email,
  });

  useEffect(() => {
    resetErrors();
    reportToSegment(types.PAGE, 'Update User Modal');
  }, []);

  useEffect(() => {
    if (updateUserSuccess && formSubmitted) {
      toggleModal(false);
      Toast('Your update has been saved!', 'success');
      setFormSubmitted(false);
    }
  }, [updateUserSuccess]);

  useEffect(() => {
    if (forgotPasswordSuccess && formSubmitted) {
      toggleModal(false);
      Toast(`An email has been sent to ${userContext.email}`);
    }
  }, [forgotPasswordSuccess]);

  useEffect(() => {
    setDisableFields(inProgress && !disableFields);
    setErroredFields(updateUserFormErrors);
  }, [inProgress, updateUserFormErrors]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      values.firstName !== userContext.firstName ||
      values.lastName !== userContext.lastName ||
      values.username !== userContext.email
    ) {
      updateUserRequest(values);
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: 'update user attempt',
        form_content: { values },
      });
      setFormSubmitted(true);
    } else {
      // just exit modal if no changes were made
      toggleModal(false);
    }
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    forgotPasswordRequest(values);
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label: 'backend action', text: 'Reset password' });
  };

  const getGeneralError = () => {
    const error = erroredFields.find(err => !err.field || err.field === 'required_fields');
    return error ? error.message : '';
  };

  const handleEmailVerificationSend = (event) => {
    sendVerificationEmailRequest();
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'backend action',
      text: event.target.innerText,
    });
  };

  const getEmailVerificationContent = () => {
    let content = (
      <React.Fragment>
        {sendVerificationEmailError && (
          <EmailVerificationError
            id="verify-email-error-container"
            color="error"
            variant="caption"
          >
            {sendVerificationEmailError}
          </EmailVerificationError>
        )}
        <div>
          {verificationEmailSent
              ? staticText.emailVerificationSent
              : staticText.emailVerificationRequired
            }
        </div>
        <EmailVerificationButton
          id="verify-email-button"
          variant="text"
          onClick={handleEmailVerificationSend}
        >
          {verificationEmailSent
              ? staticText.resendVerificationButton
              : staticText.sendVerificationButton
            }
        </EmailVerificationButton>
      </React.Fragment>
    );
    if (isSendingVerificationEmail) {
      content = <Spinner size="small" />;
    }

    return (
      <EmailVerificationContainer>
        {content}
      </EmailVerificationContainer>
    );
  };

  return (
    <Box>
      {!userContext.emailVerifiedAt && getEmailVerificationContent()}
      <UpdateUserForm onSubmit={handleSubmit}>
        <Typography id="error-container" color="error" variant="caption">{getGeneralError()}</Typography>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={2}>
          {staticText.fields.map((field, index) => (
            <Box id={`input-${index}`} key={field.name} width={field.width || '100%'}>
              <InputField
                inputName={field.name}
                inputType={field.type}
                inputValues={values[field.name]}
                inputPlaceholder={field.placeholder}
                handleChange={handleChange}
                handleBlur={handleBlur}
                inputDisabled={
                  disableFields || (field.type === 'email' && userContext.loginProvider !== 'EmailPassword')
                }
                errorMessage={getErrorMessage(field)}
              />
            </Box>
          ))}
        </Box>
        <Box mt={4} mb={2} display="flex" justifyContent="space-between">
          <Button
            id="btnResetPassword"
            variant="text"
            color="primary"
            type="button"
            disabled={disableFields}
            onClick={handleResetPassword}
          >
            {staticText.resetPassword}
          </Button>
          <Button
            id="btnSubmit"
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            disabled={disableFields}
          >
            {staticText.btnSubmit}
          </Button>
        </Box>
      </UpdateUserForm>
    </Box>
  );
};

export default UpdateUser;


UpdateUser.propTypes = {
  actions: shape({
    updateUserRequest: func.isRequired,
    forgotPasswordRequest: func.isRequired,
  }).isRequired,
  isSendingVerificationEmail: bool.isRequired,
  inProgress: bool,
  forgotPasswordSuccess: bool.isRequired,
  toggleModal: func.isRequired,
  sendVerificationEmailError: string.isRequired,
  session: shape({
    userContext: shape({
      firstName: string.isRequired,
      lastName: string.isRequired,
      email: string.isRequired,
      emailVerifiedAt: string,
    }).isRequired,
  }).isRequired,
  updateUserFormErrors: arrayOf(
    shape({
      field: string,
      message: string,
    }).isRequired,
  ),
  updateUserSuccess: bool.isRequired,
  verificationEmailSent: bool.isRequired,
};

UpdateUser.defaultProps = {
  updateUserFormErrors: [],
  inProgress: false,
};

