import { useState } from 'react';

import staticText from '../../../../../../../localization/Components/multiEmailInput';
import utils from '../../../../../../utils';

const useEmailInput = () => {
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const [emails, setEmails] = useState([]);

  const handleEmailsChange = (newEmails) => {
    const validEmails = newEmails.filter(email => utils.isEmail(email));
    if (validEmails.length !== newEmails.length) {
      setInputError(staticText.inputError);
    } else if (new Set(validEmails).size !== newEmails.length) {
      setInputError(staticText.duplicateEmailError);
    } else {
      setInputError('');
    }
    setEmails(newEmails);
  };

  const handleInputChange = (value) => {
    const newEmails = value.split(/[,\s]+/).filter(email => email !== '');
    if (newEmails.length > 1) {
      handleEmailsChange([...emails, ...newEmails]);
      setInputValue('');
    } else {
      setInputValue(value);
      if ((utils.isEmail(value) || value === '') && emails.every(email => utils.isEmail(email))) {
        setInputError('');
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.code === 'Comma' || e.code === 'Space' || e.code === 'Tab') {
      if (e.code !== 'Tab' || inputValue.length !== 0) {
        e.preventDefault();
      }
      // only register in the list of emails if inputValue is not empty
      if (inputValue.length !== 0) {
        handleEmailsChange([...emails, inputValue]);
        setInputValue('');
      }
    } else if (e.code === 'Backspace' && inputValue.length === 0) {
      const emailsCopy = [...emails];
      emailsCopy.pop();
      handleEmailsChange(emailsCopy);
    }
  };

  const getEmailsToSend = () => {
    const allEmails = [...emails];
    if (inputValue) allEmails.push(inputValue);

    const validEmails = allEmails.filter(email => utils.isEmail(email));
    if (validEmails.length !== allEmails.length) {
      setInputError(staticText.inputError);
      return null;
    }
    if (new Set(validEmails).size !== allEmails.length) {
      setInputError(staticText.duplicateEmailError);
      return null;
    }

    return validEmails;
  };

  return {
    emails,
    getEmailsToSend,
    handleEmailsChange,
    handleInputChange,
    handleKeyDown,
    inputError,
    inputValue,
    setInputError,
  };
};

export default useEmailInput;
