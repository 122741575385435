import React from 'react';
import { func, string } from 'prop-types';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import { Divider } from '../AppSelector/components/AppSelectorList/styles';
import { AccountButton, Menu, MenuItem } from './styles';

import { accountMenu as staticText } from '../../../../../../localization/Application/nav';
import useToggleMenu from '../../useToggleMenu';
import { ArrowLink } from '../../../../../../components';

const AccountMenu = ({
  userDisplayName,
  userEmail,
  logoutRequest,
  setModalView,
}) => {
  const theme = useTheme();

  const {
    menuOpen,
    toggleMenu,
    menuRef,
  } = useToggleMenu({
    label: 'account menu',
    text: '[account menu]',
  });

  const handleAccountSettingsClick = () => {
    setModalView('accountSettings');
    toggleMenu(false);
    reportToSegment(types.TRACK, eventNames.modalOpened, {
      label: staticText.accountSettings,
      title: staticText.accountSettings,
    });
  };
  const handleCookieSettingsClick = () => {
    if (window.Osano) {
      window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
    reportToSegment(types.TRACK, eventNames.modalOpened, {
      label: staticText.cookieSettings,
      text: staticText.cookieSettings,
    });
    toggleMenu(false);
  };
  const handleLogoutClick = (text) => {
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'backend action',
      text,
    });
    logoutRequest(true);
  };

  return (
    <Box sx={{ position: 'relative' }} ref={menuRef}>
      <AccountButton menuOpen={menuOpen}>
        <button type="button" onClick={toggleMenu} id="account-menu-btn">
          <img src="/assets/icons/nav/profile.svg" alt="Account menu" />
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography variant="bold">{userDisplayName}</Typography>
            <Typography variant="caption">{userEmail}</Typography>
          </Box>
          <ExpandMoreRoundedIcon htmlColor={theme.palette.primary.main} />
        </button>
      </AccountButton>
      {menuOpen && (
        <Menu>
          <MenuItem>
            <Button onClick={handleAccountSettingsClick}>{staticText.accountSettings}</Button>
          </MenuItem>
          <MenuItem>
            <Button onClick={handleCookieSettingsClick}>{staticText.cookieSettings}</Button>
          </MenuItem>
          <MenuItem>
            <Button onClick={handleLogoutClick}>{staticText.logout}</Button>
          </MenuItem>
          <Divider />
          <MenuItem>
            <ArrowLink
              toggleMenu={toggleMenu}
              link={staticText.privacy.link}
              text={staticText.privacy.linkText}
              variant="bold"
              icon="launch"
            />
          </MenuItem>
          <MenuItem>
            <ArrowLink
              toggleMenu={toggleMenu}
              link={staticText.terms.link}
              text={staticText.terms.linkText}
              variant="bold"
              icon="launch"
            />
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
};

export default AccountMenu;

AccountMenu.propTypes = {
  userDisplayName: string.isRequired,
  userEmail: string.isRequired,
  logoutRequest: func.isRequired,
  setModalView: func.isRequired,
};
