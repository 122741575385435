const staticText = {
  heading: 'Connect insights',
  underConstruction: 'This page is still under construction.',
  emptyState: 'No Connect data available for the given time period.',
  funnel: {
    heading: 'Conversion rate',
    description: 'Below are the number of vehicle owners that go through the Smartcar Connect flow to connect their vehicles to your application.',
    emptyState: {
      heading: 'No conversion data found',
      description: 'Please check the filters that have been set. Additionally, conversion data is only available for live mode requests.',
    },
  },
  sessionsFeed: {
    heading: 'Sessions feed',
    description: 'This is a feed of the most recent vehicle owners going through Smartcar Connect and their last recorded action. Click on a row to learn more about that user\'s session.',
  },
};

export default staticText;
