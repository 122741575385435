import React, { useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Box, List, ListItemText, Typography } from '@mui/material';

import { CopyButton } from '../../../../../../../../components';

import { ViewAllButton, StyledListItem } from '../../styles';

import staticText from '../../../../../../../../localization/Application/ConnectInsights/sessionDetailsDrawer';

const DISPLAY_LIMIT = 6;

const getListItem = showViewAllButton => vehicle => (
  <StyledListItem
    key={vehicle.id}
    secondaryAction={<CopyButton content={vehicle.vin} showCopyText={false} />}
    showViewAllButton={showViewAllButton}
  >
    <ListItemText
      primary={(
        <Box display="flex" justifyContent="space-between">
          <Typography>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</Typography>
          <Typography>{vehicle.vin}</Typography>
        </Box>
      )}
    />
  </StyledListItem>
);

const VehiclesList = ({ vehiclesAuthorized }) => {
  const [fullListOpen, setFullListOpen] = useState(false);

  const showViewAllButton = vehiclesAuthorized.length > DISPLAY_LIMIT;
  return (
    <div>
      <List disablePadding>
        {vehiclesAuthorized.slice(0, DISPLAY_LIMIT).map(getListItem(showViewAllButton))}
        {fullListOpen
          && vehiclesAuthorized.slice(DISPLAY_LIMIT).map(getListItem(showViewAllButton))}
      </List>
      {showViewAllButton && (
        <ViewAllButton
          onClick={() => setFullListOpen(!fullListOpen)}
          type="button"
          variant="text"
          size="small"
        >
          {fullListOpen ? staticText.viewLess : `${staticText.viewAll} (${vehiclesAuthorized.length})`}
        </ViewAllButton>
      )}
    </div>
  );
};

export default VehiclesList;

VehiclesList.propTypes = {
  vehiclesAuthorized: arrayOf(
    shape({
      id: string,
      make: string,
      model: string,
      year: string,
      vin: string,
    }),
  ).isRequired,
};
