import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { arrayOf, func, object, shape, string } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { Box, Typography, useTheme } from '@mui/material';

import ApplicationHeader from '../ApplicationHeader';
import formatDate from '../../../../services/applications/utils/dateFormatter';
import { AppsList, Headers, Rows, Row, App, AppName, LockedApp } from './styles';
import { Modal, RegisterApplication, UpsellModal } from '../../../../components';
import { gatedFeatureData } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';
import { setScrollAbility } from '../../../../services/styleUtils';
import application from '../../../../localization/Application/application';

import staticText from '../../../../localization/Application/applications';
import { AppLogoWrapper } from '../TopbarNav/components/AppSelector/styles';

const Applications = ({
  applications, lockedApplications, selectedApp, actions: { selectApplication },
}) => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const [lockedAppClicked, setLockedAppClicked] = useState(false);

  const { featureSetId } = applications[Object.keys(applications)[0]];
  const maxAppCountReached = Object.keys(applications).length >=
    gatedFeatureData(FEATURES.APPLICATIONS, featureSetId);

  /* istanbul ignore next */
  const toggleRegisterAppModal = () => {
    const segmentReport = maxAppCountReached ? application.upgradeModal : application.modal;
    reportToSegment(types.TRACK, eventNames[`modal${modalOpen ? 'Closed' : 'Opened'}`], segmentReport);
    setScrollAbility(modalOpen);
    setModalOpen(!modalOpen);
  };

  const modalConfig = {
    title: application.modal.title,
    content: (
      <RegisterApplication
        toggleModal={toggleRegisterAppModal}
        applicationNames={Object.values(applications).map(app => app.name)}
      />
    ),
  };

  const handleAppLinkClick = (appId, appName, appIsLocked) => {
    if (appIsLocked) {
      setModalOpen(true);
      setLockedAppClicked(true);
      setScrollAbility(true);
      const segmentReport = application.upgradeModal;
      reportToSegment(types.TRACK, eventNames[`modal${lockedAppClicked ? 'Closed' : 'Opened'}`],
        segmentReport);
    } else {
      selectApplication(appId);
      reportToSegment(types.TRACK, eventNames.linkClicked, {
        section: 'article',
        style: 'inline',
        path: `/apps/${appId}/`,
        text: appName,
      });
    }
  };

  /* istanbul ignore next */
  const getAppLogo = (app) => {
    const { logoUrl, name } = app;
    return (
      <AppLogoWrapper hasLogo={Boolean(logoUrl)}>
        {logoUrl ?
          <img src={logoUrl} alt={name} /> :
          <span>{name[0].toUpperCase()}</span>}
      </AppLogoWrapper>
    );
  };

  const getAppItem = (app) => {
    return (
      <App>
        <Box display="flex" alignItems="center">
          {getAppLogo(app)}
          <AppName variant="bold">{app.name}</AppName>
        </Box>
        <div>
          <Typography>{app.id}</Typography>
        </div>
        <div>
          <Typography>{formatDate(app.createdAt)}</Typography>
        </div>
      </App>
    );
  };

  useEffect(() => {
    reportToSegment(types.PAGE, 'Applications');
  }, []);

  return (
    <Box sx={{ maxWidth: theme.width.content, minWidth: '750px' }}>
      <ApplicationHeader
        heading={staticText.heading}
        dashboardPermission="write_company" // TODO: check access
        cta={{
          text: staticText.add,
          onclick: toggleRegisterAppModal,
          plusIcon: true,
          variant: 'contained',
        }}
        backLink={selectedApp && selectedApp.id && {
          path: `/apps/${selectedApp.id}`,
          text: `Back to ${selectedApp.name} application`,
        }}
      />
      <AppsList>
        <Headers>
          <Typography variant="h3">{staticText.table.headers.name}</Typography>
          <Typography variant="h3">{staticText.table.headers.id}</Typography>
          <Typography variant="h3">{staticText.table.headers.createdAt}</Typography>
        </Headers>
        <Rows>
          {Object.values(applications).map((app) => {
            const appIsLocked = lockedApplications.includes(app.id);
            return (
              <Row key={app.id}>
                {appIsLocked ? (
                  <LockedApp onClick={() => handleAppLinkClick(app.id, app.name, appIsLocked)}>
                    {getAppItem(app)}
                  </LockedApp>
                ) : (
                  <Link
                    id={app.id}
                    to={`/apps/${app.id}`}
                    onClick={() => handleAppLinkClick(app.id, app.name, appIsLocked)}
                  >
                    {getAppItem(app)}
                  </Link>
                )}
              </Row>
            );
          })}
        </Rows>
      </AppsList>
      {modalOpen && !maxAppCountReached &&
        <Modal
          {...modalConfig}
          toggleModal={toggleRegisterAppModal}
        />
      }
      {modalOpen && (maxAppCountReached || lockedAppClicked) &&
        <UpsellModal
          toggleModal={toggleRegisterAppModal}
          feature={FEATURES.APPLICATIONS}
        />
      }
    </Box>
  );
};

export default Applications;

Applications.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  applications: object.isRequired,
  lockedApplications: arrayOf(string),
  selectedApp: shape({
    id: string,
    name: string,
  }).isRequired,
  actions: shape({
    selectApplication: func.isRequired,
  }).isRequired,
};

Applications.defaultProps = {
  lockedApplications: [],
};
