import { shape, string } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const eventProps = {
  eventId: string,
  applicationId: string,
  sessionId: string,
  customerVehicleOwnerId: string,
  eventName: string,
  pageName: string,
  trackedAt: string,
  launchParamSingleSelect: string,
  launchParamSingleSelectVin: string,
  launchParamScope: string,
  make: string,
  mode: string,
  country: string,
  vehiclesAuthorized: string,
  vehiclesNotAuthorized: string,
  vehiclesIncompatible: string,
  scopesAuthorized: string,
  eventString: shape({
    username: string,
    action: string,
    text: string,
    timestamp: string,
  }),
};
