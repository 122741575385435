import applicationNameValidator from '../../services/validators/applicationNameValidator';

const staticText = {
  heading: 'Connect',
  headingCustomizations: 'Customizations',
  selectAll: 'Select all',
  clearAll: 'Clear all',
  filter: 'Filters',
  publish: 'Publish changes',
  successMessage: 'Connect configuration updated!',
  featureGated: {
    description: 'Upgrade to customize your customer experience & more',
    contactOwnersToUpgrade: 'Contact a team owner to upgrade to customize your customer experience & more',
    buttonText: 'Upgrade to publish changes',
  },
  connectTabs: [
    'Appearance',
    'Brands',
    'Countries',
  ],
  appearance: {
    heading: 'Appearance',
    description: 'Customize the Connect experience for your users by setting your application name and the color theme. Please note URL parameters passed within your Connect link will take priority over settings saved here for the same feature.',
    appNameCaption: appId => `You can change your application name on the |+|configuration@@/apps/${appId}/configuration|+| page`,
    inputs: {
      appNameInput: {
        displayName: 'Application name',
        pathOnData: 'app-name',
        placeholderText: 'Your application name as it should appear to your users',
        validator: applicationNameValidator,
        default: appName => appName,
      },
      brandingInput: {
        type: 'radio',
        options: [
          {
            label: 'Smartcar-branded',
            value: 'default',
          },
          {
            label: 'Co-branded',
            value: 'custom',
          },
        ],
        displayName: 'Page branding',
        pathOnData: 'branding',
      },
      themeInput: {
        type: 'radio',
        options: [
          {
            label: 'Light',
            value: 'light',
          },
          {
            label: 'Dark',
            value: 'dark',
          },
          {
            label: 'System',
            value: 'system',
            tooltip: "Set the theme to automatically match your users' system settings.",
          },
        ],
        displayName: 'Display theme',
        pathOnData: 'theme',
      },
      logoInput: {
        pathOnData: 'logo-url',
        displayName: 'App logo',
      },
    },
    logoUpload: {
      description: "A logo is required when 'co-branded' is selected.",
      specs: 'JPG, PNG or WEBP. 100kb max.',
      buttonText: 'Upload logo',
      acceptedTypes: ['image/webp', 'image/png', 'image/jpeg'],
      maxSizeInBytes: 100000,
      uploadingText: 'Uploading',
    },
  },
  brandManagement: {
    description: 'Select which brands your users will be able to choose from when connecting their vehicle to your application via Smartcar Connect. You can preview how the Brand Selector screen will look to your users in the preview on the right. Changes will be reflected immediately once saved.',
    displayedBrands: 'displayed brands',
    displayedOrder: 'displayed order',
    newlyAddedBrands: 'display new brands automatically',
    global: 'Learn more about brand compatibility by country on our |+|global coverage@@https://smartcar.com/global/|+| page.',
    heading: 'Brands',
    addFilter: 'Select your filters (3 filters max)',
    disabledIconText: 'There are no vehicles from this brand that match your filters.',
    apply: 'Apply',
    addRule: 'Add rule',
    displayedOrderRadio: {
      popularity: {
        label: 'Popularity (recommended)',
        tooltip: 'We recommend using rankings by popularity if you are building applications around auto insurance, roadside assistance, car sharing, and fleet management.',
      },
      alphabetical: {
        label: 'Alphabetical',
        tooltip: 'Alphabetical rankings may negatively impact your app’s conversion rates with Smartcar Connect.',
      },
    },
    displayNewlyAddedBrands: {
      enabled: {
        label: 'Enabled (recommended)',
        tooltip: 'Whenever Smartcar launches a new brand, it will be added to the brand list in Connect automatically.',
      },
      disabled: {
        label: 'Disabled',
      },
    },
    singleBrandSuggestion: {
      heading: 'Helpful tip',
      text: 'When selecting only one brand, consider using |+|Brand Select mode@@https://smartcar.com/docs/connect/advanced-config/flows|+| instead to streamline the connection process for your application users.',
      close: 'Close',
    },
  },
  connectPreview: {
    heading: 'connect preview',
    selectBrand: 'Select your brand',
    backToApplication: 'Back to application',
    brandNotPresent: "I don't see my brand...",
    errors: {
      brandIncompatible: {
        header: 'Brand not supported',
        description: 'Unfortunately, the brands supported by this application are not compatible with the country you are located in. Please contact the application owner to learn more.',
        cta: 'Back to application',
      },
      noSelectedMake: {
        description: 'Please select at least one brand from the list to see a preview of Connect.',
      },
    },
    search: 'Search',
    footerText: '{{applicationName}} uses Smartcar to connect to your vehicle. Logos and brand names are for identification purposes only and do not indicate endorsement of or affiliation with Smartcar or {{applicationName}}',
  },
  countries: {
    heading: 'Countries',
    description: 'Select which countries users will be able to choose from when launching the Connect flow. You can preview how the country options change on the Preamble screen in the preview to the right. Changes will be reflected immediately once published.',
  },
  errors: {
    applicationMakes: 'Sorry, we encountered an error fetching the list of brands. Please try again later.',
    connectConfig: 'Sorry, we encountered an error fetching your previous configuration settings. Please try again later.',
    makesByCountry: 'Sorry, we encountered an error fetching the list of brands for the selected country. Please try again later',
    updatingConnectConfig: 'Sorry, we encountered an error updating your configuration settings. Please try again later.',
    updatingAppLogo: 'Sorry, we encountered an error uploading your app logo. Please try again later.',
    brandManagementFilterOptions: 'Sorry, we encountered an error fetching filter options. Please try again later.',
  },
};

export default staticText;
