import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Login from './Login';
import { actions as loginActions } from '../../../../services/auth/reducers';

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    getLoginProviderRequest,
    authenticateDeveloperRequest,
    resetErrors,
    setPostAuthRedirect,
    triggerOauthFlowRequest,
    setPrefilledInvitedUsername,
    resetOauthInvitation,
  } = loginActions;

  return {
    actions: bindActionCreators({
      getLoginProviderRequest,
      authenticateDeveloperRequest,
      resetErrors,
      setPostAuthRedirect,
      triggerOauthFlowRequest,
      setPrefilledInvitedUsername,
      resetOauthInvitation,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
