import React, { useEffect } from 'react';
import { string, func, shape, bool } from 'prop-types';
import Spinner from '../../components/Spinner';

/**
 * This component renders a loading spinner.
 * We render the component when the user is logged in but we have not yet routed to /apps/:id.
 */

const Initialization = (props) => {
  const {
    actions,
    history,
    location,
    postAuthRedirect,
    showUserSetup,
  } = props;

  useEffect(() => {
    if (showUserSetup) {
      history.push('/setup/user');
    } else if (postAuthRedirect) {
      history.push(postAuthRedirect);
      actions.setPostAuthRedirect('');
    } else if (location.pathname === '/') {
      actions.initializeOnboarding();
    } else {
      actions.initializeBilling();
      actions.initializeApplications();
    }
  }, [location.pathname]);

  return <Spinner delay={200} />;
};

export default Initialization;

Initialization.propTypes = {
  actions: shape({
    initializeApplications: func.isRequired,
    initializeBilling: func.isRequired,
    initializeOnboarding: func.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
  postAuthRedirect: string.isRequired,
  showUserSetup: bool.isRequired,
};
