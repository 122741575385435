import React from 'react';
import { func } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { Modal } from '../../../../../../components';
import UpdateUser from './components/UpdateUser';
import staticText from '../../../../../../localization/Authentication/updateUser';

export const closeModalHelper = (toggleModal) => {
  return (withTracking = true) => {
    toggleModal(null);
    if (withTracking) {
      reportToSegment(types.TRACK, eventNames.modalClosed, { label: 'account settings', title: staticText.title });
    }
  };
};

const AccountSettingsModal = ({
  toggleModal,
}) => {
  const closeModal = closeModalHelper(toggleModal);

  const content = (
    <UpdateUser
      toggleModal={closeModal}
    />
  );

  return <Modal toggleModal={closeModal} content={content} title={staticText.title} />;
};

export default AccountSettingsModal;

AccountSettingsModal.propTypes = {
  toggleModal: func.isRequired,
};
