import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { reportToSegment, eventNames, types } from '@smartcar/morse';
import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';

import staticText from '../../../../localization/Application/connect-config';
import {
  Appearance,
  BrandManagement,
  ConnectPreview,
  Countries,
} from './components';
import ApplicationHeader from '../ApplicationHeader';
import { TabsNav } from '../../../../global-styles/components';
import { TabWrapper } from './styles';
import { Feedback, TabPanel, UpgradeButton } from '../../../../components';
import { gatedFeatureData } from '../../../../services/featureGate';
import FEATURES from '../../../../services/featureGate/features';

import { initialState } from './reducers';

const ConnectCustomizations = ({
  actions: {
    fetchApplicationMakesRequest,
    fetchConnectConfigRequest,
    fetchBrandManagementFilterOptions,
    updatePreviewSettings,
    updateCurrentTab,
  },
  appName,
  connectConfig,
  featureSetId,
  rolePermissions,
  currentTab,
}) => {
  const [suggestionDialogOpen, setSuggestionDialogOpen] = useState(false);
  const theme = useTheme();
  const isGated = gatedFeatureData(FEATURES.CONNECT_CONFIG, featureSetId);
  const canWriteBilling = rolePermissions.includes('write_billing');

  const handleTabChange = (e, newValue) => {
    reportToSegment(types.TRACK, eventNames.tabSelected, {
      text: e.target.textContent,
      label: 'connect panel',
    });
    // reset connect preview settings from any unsaved changes
    // (except selected country)
    updatePreviewSettings({
      selectedAppName: appName,
      selectedTheme: (connectConfig && connectConfig.theme) ||
        initialState.previewSettings.selectedTheme,
      selectedSortOrder: (connectConfig && connectConfig.supportedMakesSortOrder) ||
        initialState.previewSettings.selectedSortOrder,
      selectedBranding:
        connectConfig && connectConfig.brandedHeader ?
          'custom' : initialState.previewSettings.selectedBranding,
      selectedLogo:
        connectConfig && connectConfig.logoUrl ?
          connectConfig.logoUrl : initialState.previewSettings.selectedLogo,
    });
    updateCurrentTab(newValue);
  };

  const handlePreviewSettingsUpdate = ({ name, value }) => {
    let key = name;
    if (name === 'app-name') key = 'selectedAppName';
    if (name === 'theme') key = 'selectedTheme';
    if (name === 'branding') key = 'selectedBranding';
    updatePreviewSettings({ [key]: value });
  };

  const renderErrorMessage = message => (
    <Typography variant="caption" color="error">
      {message || staticText.errors.updatingConnectConfig}
    </Typography>
  );

  const messageByPermission = canWriteBilling ?
    staticText.featureGated.description : staticText.featureGated.contactOwnersToUpgrade;
  const upgradeMessage = isGated ? <Feedback type="info" message={messageByPermission} /> : null;

  const upgradeCta = isGated
    ? <UpgradeButton text={staticText.featureGated.buttonText} feature={FEATURES.CONNECT_CONFIG} />
    : null;

  useEffect(() => {
    fetchApplicationMakesRequest();
    fetchConnectConfigRequest();
    fetchBrandManagementFilterOptions();
  }, []);

  useEffect(() => {
    reportToSegment(types.PAGE, 'Customizations');
  }, []);

  return (
    <Box sx={{ maxWidth: theme.width.content, minWidth: '1100px' }}>
      <ApplicationHeader
        heading={staticText.headingCustomizations}
      />
      <TabsNav
        color="transparent"
        component="nav"
        elevation={0}
      >
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          indicatorColor="primary"
        >
          <Tab
            key={staticText.connectTabs[0]}
            label={staticText.connectTabs[0]}
            value={0}
            disableRipple
            active="primary"
          />
          <Tab
            key={staticText.connectTabs[1]}
            label={staticText.connectTabs[1]}
            value={1}
            disableRipple
            active="primary"
          />
          <Tab
            key={staticText.connectTabs[2]}
            label={staticText.connectTabs[2]}
            value={2}
            disableRipple
            active="primary"
          />
        </Tabs>
      </TabsNav>
      <div>
        <TabPanel value={currentTab} index={0} pageName="connect">
          <TabWrapper>
            <Appearance
              handlePreviewSettingsUpdate={handlePreviewSettingsUpdate}
              renderErrorMessage={renderErrorMessage}
              upgradeMessage={upgradeMessage}
              upgradeCta={upgradeCta}
            />
            <ConnectPreview previewPage="preamble" />
          </TabWrapper>
        </TabPanel>
        <TabPanel value={currentTab} index={1} pageName="connect">
          <TabWrapper>
            <BrandManagement
              renderErrorMessage={renderErrorMessage}
              upgradeMessage={upgradeMessage}
              upgradeCta={upgradeCta}
              suggestionDialogOpen={suggestionDialogOpen}
              setSuggestionDialogOpen={setSuggestionDialogOpen}
            />
            <ConnectPreview previewPage="brandSelector" showHeader />
          </TabWrapper>
        </TabPanel>
        <TabPanel value={currentTab} index={2} pageName="connect">
          <TabWrapper>
            <Countries
              renderErrorMessage={renderErrorMessage}
              upgradeMessage={upgradeMessage}
              upgradeCta={upgradeCta}
            />
            <ConnectPreview previewPage="preamble" displayHeight={800} />
          </TabWrapper>
        </TabPanel>
      </div>
    </Box>
  );
};

export default ConnectCustomizations;

ConnectCustomizations.propTypes = {
  actions: shape({
    fetchApplicationMakesRequest: func.isRequired,
    fetchConnectConfigRequest: func.isRequired,
    updatePreviewSettings: func.isRequired,
    updateCurrentTab: func.isRequired,
    fetchBrandManagementFilterOptions: func.isRequired,
  }).isRequired,
  appName: string.isRequired,
  connectConfig: shape({
    theme: string,
    supportedMakesSortOrder: string,
    brandedHeader: bool,
  }),
  featureSetId: string.isRequired,
  rolePermissions: arrayOf(string).isRequired,
  currentTab: number,
};

ConnectCustomizations.defaultProps = {
  connectConfig: {},
  currentTab: 0,
};
