import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ConnectCustomizations from './ConnectCustomizations';
import { actions } from './reducers';
import { selectors as orgSelectors } from '../../../../services/organizations/reducers';

function mapStateToProps(state) {
  const currentOrganization = orgSelectors.getCurrentOrganization(state);
  const { name } =
    state.applications.applications[state.applications.selectedApplication];
  return {
    ...state.connectCustomizations,
    appName: name,
    featureSetId: currentOrganization.featureSetId,
    rolePermissions: currentOrganization.rolePermissions,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchApplicationMakesRequest,
    fetchConnectConfigRequest,
    fetchBrandManagementFilterOptions,
    updatePreviewSettings,
    updateCurrentTab,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        fetchApplicationMakesRequest,
        fetchConnectConfigRequest,
        fetchBrandManagementFilterOptions,
        updatePreviewSettings,
        updateCurrentTab,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCustomizations);
