import React from 'react';
import PropTypes from 'prop-types';
import AuthenticatedRoute from '../AuthenticatedRoute';

const propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  redirect: PropTypes.string.isRequired,
};

const PublicRoute = props => <AuthenticatedRoute {...props} isPrivate={false} />;

PublicRoute.propTypes = propTypes;

export default PublicRoute;
