import React, { useEffect } from 'react';
import { func, bool, string } from 'prop-types';

import { Box, Divider } from '@mui/material';
import { LoginMethod, LoginMethodContainer } from './styles';
import staticText from '../../../../../../localization/Authentication/login';

const LoginButtons = ({
  disableFields,
  userAuthFlow,
  triggerOauthFlowRequest,
  resetErrors,
  loginProvider,
  automaticallyLoginViaOauth,
}) => {
  const handleOAuth = async (provider, flow) => {
    // Determines which redirectUri is needed based on the env (ie. local, qa, prod)
    const redirectUri = `${window.location.origin}/callback`;

    triggerOauthFlowRequest({
      redirectUri,
      provider,
      authFlow: flow,
    });
  };

  useEffect(() => {
    if (automaticallyLoginViaOauth) {
      handleOAuth(loginProvider, userAuthFlow);
    }
  }, [automaticallyLoginViaOauth]);

  const dynamicOauthButtonText = userAuthFlow === 'login' ? 'Login' : 'Sign up';

  return (
    <Box>
      <LoginMethodContainer>
        {staticText.loginMethods.map(method => (
          <LoginMethod
            id={method.value}
            name="login_method"
            value={method.value}
            className="login_button"
            disabled={disableFields}
            onClick={() => {
                resetErrors();
                handleOAuth(method.value, userAuthFlow);
              }
            }
          >
            <img src={method.img} alt={method.value} width="24" />
            <span>{method.text(dynamicOauthButtonText)}</span>
          </LoginMethod>
  ))}
      </LoginMethodContainer>
      <Divider>or</Divider>
    </Box>

  );
};

export default LoginButtons;

LoginButtons.propTypes = {
  triggerOauthFlowRequest: func.isRequired,
  resetErrors: func.isRequired,
  disableFields: bool.isRequired,
  userAuthFlow: string.isRequired,
  loginProvider: string.isRequired,
  automaticallyLoginViaOauth: bool.isRequired,
};

LoginButtons.defaultProps = {};
