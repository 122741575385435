import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { bool, element, func, number, object, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ApiCapabilitiesTable, MMYForm, VinForm } from './components';
import { AlignmentFormControl, AlignmentFormLabel, RadioLabel, Select } from './styles';
import { Wrapper } from '../shared/styles';
import { SelectIcon } from '../../../../../../components';
import staticText from '../../../../../../localization/Application/Simulator/create';
import tripOptions from '../SimulateVehicle/utils/tripOptions';
import { generateVin } from '../../utils/utils';
import { SimulatorHeader } from '../shared';

/* istanbul ignore next */
const CreateVehicle = ({
  actions: {
    createVehicle,
    cancelFetching,
    fetchVehicleCapabilities,
    fetchVinCompatibility,
    setSelectedRegion,
    setSelectedVehicle,
  },
  isFetchingSimulator,
  simulatedVehicles,
  applicationId,
  allCompatibleVehicles,
  selectedVehicle,
  selectedRegion,
  searchType,
  setSearchType,
  vinCompatibility,
  setIsFetching,
  history,
  upgradeCta,
  upgradeMessage,
}) => {
  const theme = useTheme();

  const [alignment, setAlignment] = useState(searchType || 'MMY');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setSelectedVehicle({});
    reportToSegment(types.PAGE, 'Simulator - Create Vehicle');
  }, []);

  const handleRegionChange = (e) => {
    const region = e.target.value;
    setSelectedVehicle({});
    setSelectedRegion(region);
    if (region !== 'US') {
      setAlignment('MMY');
    }

    reportToSegment(types.TRACK, eventNames.dropdownClosed, {
      label: 'select',
      text: '[simulator] change region',
    });
  };

  const handleSearchTypeChange = (e) => {
    setErrorMessage('');
    setSelectedVehicle({});
    const newAlignment = e.target.value;
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
    // only allow US as the region when VIN is selected
    if (newAlignment === 'VIN') {
      setSelectedRegion('US');
    }
  };

  const handleVehicleSearchSubmit = async (formName, vehicle) => {
    const vin = vehicle.vin || generateVin();
    setSelectedVehicle({
      make: vehicle.make,
      model: vehicle.model,
      year: vehicle.year,
      vehicleDefinitionId: vehicle.vehicleDefinitionId,
      vin,
    });

    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: formName,
      form_content: vehicle,
    });
    setSearchType(formName.includes('MMY') ? 'MMY' : 'VIN');
  };

  const submitVehicle = () => {
    setIsFetching(true);
    createVehicle(applicationId, {
      regionCode: selectedRegion,
      vehicleDefinitionId: selectedVehicle.vehicleDefinitionId,
      vin: selectedVehicle.vin,
      make: selectedVehicle.make,
      model: selectedVehicle.model,
      year: selectedVehicle.year,
      vehicleType: selectedVehicle.vehicleType,
    });
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      { label: 'backend action', text: `[simulator] create vehicle ${selectedVehicle.vin}` },
    );
    setSearchType('');
    history.push(`/apps/${applicationId}/simulator/${selectedVehicle.vin}`);
  };

  useEffect(() => {
    if (selectedVehicle.vehicleDefinitionId && !selectedVehicle.endpointsWithLatency) {
      fetchVehicleCapabilities(applicationId, selectedVehicle.vehicleDefinitionId);
    }
  }, [selectedVehicle.vehicleDefinitionId]);

  return (
    <React.Fragment>
      <SimulatorHeader
        heading={staticText.heading}
        applicationId={applicationId}
        setSelectedVehicle={setSelectedVehicle}
        setIsFetching={setIsFetching}
      />
      <Wrapper customHeight="760px">
        <div>
          <Typography sx={{ width: '800px' }}>{staticText.subheading}</Typography>
          <AlignmentFormControl>
            <AlignmentFormLabel>
              {staticText.alignmentFormLabel}
            </AlignmentFormLabel>
            <RadioGroup
              row
              value={alignment}
              onChange={handleSearchTypeChange}
              sx={{ marginRight: theme.spacing(1) }}
            >
              <RadioLabel value="MMY" control={<Radio size="small" />} label={staticText.mmyForm.heading} />
              <RadioLabel value="VIN" control={<Radio size="small" />} label={staticText.vinForm.heading} />
            </RadioGroup>
          </AlignmentFormControl>
          <Box mt={2} display="flex">
            <form>
              <FormControl variant="outlined">
                <InputLabel id="region-select-label">{staticText.regionForm.label}</InputLabel>
                <Select
                  name="region"
                  value={selectedRegion}
                  id="region-select"
                  label={staticText.regionForm.label}
                  labelId="region-select-label"
                  onChange={handleRegionChange}
                  IconComponent={SelectIcon}
                  variant="outlined"
                  disabled={alignment === 'VIN'}
                >
                  {tripOptions.regions.map(option => (
                    <MenuItem key={option.regionCode} value={option.regionCode}>
                      {option.region}
                    </MenuItem>
                ))}
                </Select>
              </FormControl>
            </form>
            {(allCompatibleVehicles[selectedRegion]
              && Object.keys(allCompatibleVehicles[selectedRegion]).length) && (
                <React.Fragment>
                  {alignment === 'MMY' && (
                    <MMYForm
                      allCompatibleVehicles={allCompatibleVehicles}
                      handleVehicleSearchSubmit={handleVehicleSearchSubmit}
                      showLastSearch={searchType === 'MMY'}
                      selectedVehicle={selectedVehicle}
                      setSelectedVehicle={setSelectedVehicle}
                      selectedRegion={selectedRegion}
                    />
                  )}
                  {alignment === 'VIN' && (
                    <VinForm
                      vinCompatibility={vinCompatibility}
                      allVins={Object.keys(simulatedVehicles)}
                      applicationId={applicationId}
                      compatibleVehicles={allCompatibleVehicles[selectedRegion]}
                      disabled={selectedRegion !== 'US'}
                      isFetchingVinCompatibility={isFetchingSimulator.vinCompatibility}
                      fetchVinCompatibility={fetchVinCompatibility}
                      handleVehicleSearchSubmit={handleVehicleSearchSubmit}
                      showLastSearch={searchType === 'VIN'}
                      selectedVehicle={selectedVehicle}
                      setSelectedVehicle={setSelectedVehicle}
                      selectedRegion={selectedRegion}
                      cancelFetching={cancelFetching}
                      setErrorMessage={setErrorMessage}
                    />
                  )}
                </React.Fragment>
              )
            }
          </Box>
          <Box mt={1} height="21px">
            <Typography variant="caption" color="error">{errorMessage}</Typography>
          </Box>
        </div>
        <ApiCapabilitiesTable
          selectedVehicle={selectedVehicle}
          isFetchingSimulator={isFetchingSimulator}
        />
        {selectedVehicle.endpointsWithLatency && (
          <React.Fragment>
            <Box marginTop={4}>
              {upgradeCta ||
                <Button
                  id="button-submit-vehicle"
                  variant="contained"
                  size="large"
                  onClick={submitVehicle}
                  disabled={!!upgradeMessage}
                >
                  {staticText.useVehicleButton}
                </Button>
              }
            </Box>
          </React.Fragment>
        )}
      </Wrapper>
    </React.Fragment>
  );
};

export default withRouter(CreateVehicle);

CreateVehicle.propTypes = {
  actions: shape({
    fetchRegionCompatibleVehicles: func.isRequired,
    fetchVinCompatibility: func.isRequired,
    setSelectedRegion: func.isRequired,
    setSelectedVehicle: func.isRequired,
    cancelFetching: func.isRequired,
  }).isRequired,
  isFetchingSimulator: shape({
    vinCompatibility: bool.isRequired,
  }).isRequired,
  applicationId: string.isRequired,
  simulatedVehicles: object.isRequired,
  allCompatibleVehicles: object.isRequired,
  selectedRegion: string.isRequired,
  selectedVehicle: shape({
    make: string,
    model: string,
    vin: string,
    year: number,
    vehicleDefinitionId: string,
  }),
  vinCompatibility: object.isRequired,
  searchType: string,
  setSearchType: func.isRequired,
  setIsFetching: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  upgradeCta: element,
  upgradeMessage: element,
};

CreateVehicle.defaultProps = {
  searchType: '',
  selectedVehicle: {},
  upgradeCta: null,
  upgradeMessage: null,
};
