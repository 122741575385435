import React from 'react';
import PropTypes from 'prop-types';
import AuthenticatedRoute from '../AuthenticatedRoute';

const propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  redirect: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    setEmailToken: PropTypes.func.isRequired,
  }).isRequired,
};

const PrivateRoute = (props) => {
  React.useEffect(() => {
    if (props.location.pathname === '/verify-email') {
      if (props.location.search) {
        const emailToken = new URLSearchParams(props.location.search).get('email_token');
        if (emailToken) {
          props.actions.setEmailToken(emailToken);
        }
      }
    }
    // for the catch-all route, preserve any additional path information so we can redirect
    // to the specified path once the applications have been initialized
    if (props.path === '/' && props.location.pathname !== '/') {
      localStorage.setItem('deep_redirect', `${props.location.pathname}${props.location.search}`);
    }
  }, []);
  return <AuthenticatedRoute {...props} isPrivate />;
};

PrivateRoute.propTypes = propTypes;

export default PrivateRoute;
