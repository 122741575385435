import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { func, shape, string } from 'prop-types';
import { Box, useTheme } from '@mui/material';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import applicationsProps, { defaultProps as applicationsDefaultProps } from '../../types/applications';
import {
  AccountTrialCountdownTracker,
  SelectAppsModal,
  Spinner,
  Toast,
  RegisterApplication,
  Modal,
  UpsellModal,
  Feedback,
} from '../../components';
import {
  ApplicationNotFound,
  Applications,
  Billing,
  Configuration,
  Connect,
  SidebarNav,
  Simulator,
  Members,
  OrgSettings,
  Overview,
  TopbarNav,
  Vehicles,
  Webhooks,
  Logs,
  SuspendedOrg,
} from './components';

import { TopbarWrapper, SidebarWrapper, MainWrapper, Main } from './styles';
import staticText from '../../localization/Application/application';
import { setScrollAbility } from '../../services/styleUtils';
import { isInTrial, daysLeftInTrial } from './components/Billing/utils/textFormatters';
import billingStaticText from '../../localization/Application/Billing/billing';

import { gatedFeatureData, isSelfServe } from '../../services/featureGate';
import FEATURES from '../../services/featureGate/features';
import utils from '../utils';
import { launchChat, shutdownChat, SUPPORT_CHAT_ID } from '../../services/front/front';

import { getOrgApps } from './utils';
import api from '../../services/api/api';

const Application = ({
  actions,
  userContext,
  organization,
  selectedApplication,
  selectedOrganization,
  applications,
  billingInfo,
  lockedApplications,
  isFetching,
  isFetchingOrganizations,
  history,
  match,
  applicationsErrorMessage,
  secretErrorMessage,
  lockedApplicationsErrorMessage,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activityStart, setActivityStart] = useState(Date.now());
  const [sidebarNavExpanded, setSidebarNavExpanded] = useState(true);
  const theme = useTheme();

  const { applicationId } = match.params;
  const pathParts = history.location.pathname.split('/');
  const basePath = pathParts[1];
  const pagePath = pathParts[pathParts.length - 1];

  const applicationIds = Object.keys(applications);
  const orgApps = getOrgApps(applications, organization.id);
  const ownedAppIds = Object.keys(orgApps);
  const appLimit = gatedFeatureData(FEATURES.APPLICATIONS, organization.featureSetId);
  const isAppAmountValid = Boolean(!appLimit)
    || ownedAppIds.length - lockedApplications.length === appLimit
    || (ownedAppIds.length <= appLimit && lockedApplications.length === 0);
  const isValidApp = basePath === 'apps' && applicationIds.includes(applicationId) &&
    !lockedApplications.includes(applicationId);
  const maxAppCountReached = Object.keys(orgApps).length >= appLimit;

  const currentOrgFeatureSetId = organization.featureSetId;

  // Suspended featureSetId ('216cca36-5028-4ea8-aeb2-d7acdcaf20b4')
  const isSuspendedFeatureSetId = currentOrgFeatureSetId === '216cca36-5028-4ea8-aeb2-d7acdcaf20b4';

  useEffect(() => {
    // componentDidMount
    if (utils.forceVerification(
      userContext.developerCreatedAt,
      userContext.developerEmailVerifiedAt,
    )) {
      history.push('/verify-email');
    }
    actions.fetchApplications();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        reportToSegment(types.TRACK, eventNames.applicationActive, {
          msActive: Date.now() - activityStart,
          applicationId,
        });
      } else {
        setActivityStart(Date.now());
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    reportToSegment(types.TRACK, eventNames.applicationLoaded, {
      ...(applicationId && { applicationId }),
    });

    // componentWillUnmount
    return () => {
      reportToSegment(types.TRACK, eventNames.applicationActive, {
        msActive: Date.now() - activityStart,
        applicationId,
      });
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      shutdownChat();
    };
  }, []);

  useEffect(() => {
    if (
      organization &&
      organization.featureSetId &&
      !gatedFeatureData(FEATURES.CHAT_SUPPORT, organization.featureSetId)
    ) {
      launchChat(SUPPORT_CHAT_ID, {
        applicationId,
        developerId: selectedOrganization,
        dashboardUserId: userContext.dashboardUserId,
        firstName: userContext.firstName,
        lastName: userContext.lastName,
        email: userContext.email,
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    // Handle application selection
    if (basePath === 'apps' && selectedApplication !== applicationId && isValidApp) {
      actions.selectApplication(applicationId);
      actions.fetchApplications();
    }
  }, [selectedApplication, applicationId]);

  useEffect(() => {
    // organization selection
    const appOrganizationId = _.get(applications, [applicationId, 'organizationId']);

    if (appOrganizationId && appOrganizationId !== selectedOrganization) {
      actions.selectOrganization(appOrganizationId);
      history.push('/');
    }
  }, [selectedApplication, selectedOrganization]);

  useEffect(() => {
    // Error handling toasts
    if (applicationsErrorMessage) {
      Toast(applicationsErrorMessage, 'warn');
    }

    if (secretErrorMessage) {
      Toast(secretErrorMessage, 'warn');
    }

    if (lockedApplicationsErrorMessage) {
      Toast(lockedApplicationsErrorMessage, 'warn');
    }
  }, [applicationsErrorMessage, secretErrorMessage, lockedApplicationsErrorMessage]);

  const toggleExpandedMenu = () => {
    setSidebarNavExpanded(!sidebarNavExpanded);
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: sidebarNavExpanded ? 'close menu' : 'open menu',
      text: sidebarNavExpanded ? '< [close nav]' : '> [open nav]',
    });
  };

  const toggleModal = (withTracking = true) => {
    const segmentReport = maxAppCountReached
      ? staticText.upgradeModal
      : staticText.modal;
    if (withTracking) {
      reportToSegment(
        types.TRACK,
        eventNames[`modal${modalOpen ? 'Closed' : 'Opened'}`],
        segmentReport,
      );
    }
    setScrollAbility(modalOpen);
    setModalOpen(!modalOpen);
  };

  const feedbackContent = [];
  // Only displaying payment error for self-serve feature sets
  if (billingInfo.delinquent && isSelfServe(organization.featureSetId)) {
    const getStripePortalURL = async () => {
      const URL = (await api.fetchStripeUrl(organization.id, 'portal', billingInfo.stripeCustomerId));
      window.location.href = URL;
    };

    feedbackContent.push({
      key: 'paymentError',
      message: billingStaticText.paymentErrorFeedback,
      type: 'warn',
      buttonOnClick: getStripePortalURL,
      buttonText: 'View',
    });
  }

  const selectApplicationsModal = !isAppAmountValid && applicationId && (
    <SelectAppsModal
      orgApps={orgApps}
      applicationId={applicationId}
      featureSetId={organization.featureSetId}
    />
  );

  const showTrialBanner = isInTrial(billingInfo.planName);

  let application;
  if (basePath === 'apps' && !isValidApp) {
    application = (
      <div>
        {modalOpen && !maxAppCountReached && (
          <Modal
            {...{
              title: staticText.modal.title,
              content: (
                <RegisterApplication
                  toggleModal={toggleModal}
                  applicationNames={_.map(orgApps, 'name')}
                />
              ),
            }}
            toggleModal={toggleModal}
          />
        )}
        {modalOpen && maxAppCountReached && (
          <UpsellModal
            toggleModal={toggleModal}
            feature={FEATURES.APPLICATIONS}
          />
        )}
        <ApplicationNotFound
          applications={orgApps}
          toggleModal={toggleModal}
          applicationId={applicationId}
          pathname={history.location.pathname}
          organizationId={organization.id}
          dashboardRole={organization.dashboardRole}
        />
      </div>
    );
  } else {
    application = (
      <Box display="flex" flexDirection="column" alignItems="stretch">
        <TopbarWrapper>
          {showTrialBanner && (
            <AccountTrialCountdownTracker
              message={`${staticText.trialBanner.message} ${daysLeftInTrial(
                billingInfo.billingPeriodEndDate,
              )} ${staticText.trialBanner.durationType}`}
            />
          )}
          <TopbarNav
            pathname={history.location.pathname}
            orgApps={orgApps}
            view={basePath}
          />
        </TopbarWrapper>
        <Box
          display="flex"
          marginTop={showTrialBanner ?
            `calc(${theme.height.topbar} + ${theme.height.banner})` :
            theme.height.topbar}
        >
          <SidebarWrapper onClick={toggleExpandedMenu}>
            <SidebarNav
              pathname={history.location.pathname}
              applicationId={selectedApplication}
              menuExpanded={sidebarNavExpanded}
              toggleExpandedMenu={toggleExpandedMenu}
              view={basePath}
            />
          </SidebarWrapper>
          <MainWrapper sidebarNavExpanded={sidebarNavExpanded}>
            <Main>
              {feedbackContent && pagePath !== 'billing' &&
                <Box sx={{ maxWidth: pagePath === 'vehicles' || pagePath === 'logs' ? '1600px' : theme.width.content }}>
                  {feedbackContent.map((props, index) => (
                    <Feedback
                      {...props}
                      additionalClassNames={[
                        'm-t-sm',
                        index + 1 === feedbackContent.length ? 'm-b-med' : 'm-b-sm',
                      ]}
                    />
                  ))}
                </Box>
              }
              {
                isSuspendedFeatureSetId ?
                (
                  <SuspendedOrg />
                )
                : (
                  <Switch>
                    {/* APPS ROUTES */}
                    <Route
                      path="/apps/:applicationId/overview"
                      component={Overview}
                    />
                    <Route
                      path="/apps/:applicationId/configuration"
                      component={Configuration}
                    />
                    <Route
                      path="/apps/:applicationId/connect"
                      component={Connect}
                    />
                    <Route
                      path="/apps/:applicationId/webhooks"
                      component={Webhooks}
                    />
                    <Route
                      path="/apps/:applicationId/simulator/:vin"
                      component={Simulator}
                    />
                    <Route
                      path="/apps/:applicationId/simulator"
                      component={Simulator}
                    />
                    <Route
                      path="/apps/:applicationId/logs"
                      component={Logs}
                    />
                    <Route
                      path="/apps/:applicationId/vehicles"
                      component={Vehicles}
                    />
                    <Route
                      path="/team/applications"
                      component={Applications}
                    />
                    <Route
                      path="/team/settings"
                      component={OrgSettings}
                    />
                    <Route
                      path="/team/members"
                      component={Members}
                    />
                    <Route
                      path="/team/billing"
                      component={Billing}
                    />
                    <Redirect
                      to={
                    basePath === 'apps'
                      ? '/apps/:applicationId/overview'
                      : '/team/applications'
                  }
                    />
                  </Switch>
                   )
              }
            </Main>
          </MainWrapper>
        </Box>
      </Box>
    );
  }

  return (isFetching || isFetchingOrganizations) ? (
    <Spinner size="page" delay={200} />
  ) : (
    <React.Fragment>
      {application}
      {selectApplicationsModal}
    </React.Fragment>
  );
};

export default Application;

Application.propTypes = {
  ...applicationsProps,
  actions: shape({
    fetchApplications: func.isRequired,
    selectApplication: func.isRequired,
  }),
  match: shape({
    params: shape({
      applicationId: string,
    }).isRequired,
  }).isRequired,
  history: shape({
    location: shape({
      pathname: string.isRequired,
    }).isRequired,
  }).isRequired,
};

Application.defaultProps = {
  ...applicationsDefaultProps,
};
