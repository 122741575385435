import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from '../../reducers';


import SessionDetailsDrawer from './SessionDetailsDrawer';

function mapStateToProps(state) {
  return {
    ...state.connectInsights,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchSessionDetailsRequest,
    resetSessionDetails,
  } = actions;
  return {
    actions: bindActionCreators(
      {
        fetchSessionDetailsRequest,
        resetSessionDetails,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionDetailsDrawer);
