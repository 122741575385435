import { gatedTeamRoles } from '../../scenes/Application/components/Members/utils/roles';

const gatedFeatures = {
  plans: [
    {
      planName: 'free',
      featureSetId: 'dc784bad-8b0e-41cc-acad-1d42e62317cf',
      selfServe: true,
      features: {
        vehicleSimulator: true, // Display upgrade messages and cta
        scheduledWebhooks: true, // Display upgrade messages and cta
        logDays: 1, // Minimum log filter date set to logDays prior to current date
        logExport: true, // Changes export onClick to show upsell modal
        logFilter: true, // Hides filters (make/dates)
        applications: 1, // When a user attempts to create more applications, show modal
        chatSupport: true, // Hides chat support
        connectConfig: true, // Display upgrade messages and cta
        teams: false,
        teamsRoles: gatedTeamRoles, // roles not available on teams
        connectInsightsDays: 1,
        connectFunnel: true,
      },
    },
    {
      planName: 'build',
      legacy: true,
      featureSetId: '78473526-443f-44e9-b07b-2645da7143c7',
      selfServe: true,
      features: {
        logDays: 7,
        logExport: true,
        applications: 2,
        chatSupport: true,
        connectConfig: true,
        teams: false,
        teamsRoles: gatedTeamRoles,
        connectInsightsDays: 3,
        connectFunnel: true,
      },
    },
    {
      planName: 'scale',
      legacy: true,
      featureSetId: '2b52a32d-9351-40bb-82d9-ab2213eb3a8c',
      selfServe: true,
      features: {
        logDays: 90,
        applications: 10,
        teams: false,
        teamsRoles: gatedTeamRoles,
        chatSupport: true,
        connectInsightsDays: 7,
        connectFunnel: true,
      },
    },
    {
      planName: 'enterprise',
      featureSetId: '01be4b83-49bb-4aab-8760-cc362e455b7a',
      selfServe: false,
      features: {
        logDays: 365,
        applications: 50,
        teamsRoles: [], // all roles available on teams
        connectInsightsDays: 14,
      },
    },
    {
      planName: 'build',
      featureSetId: '68e964a8-397c-4a7b-bc7e-e7897ed4850e',
      selfServe: true,
      features: {
        scheduledWebhooks: true,
        logDays: 7,
        logExport: true,
        logFilter: true,
        applications: 2,
        connectConfig: true,
        teams: false,
        teamsRoles: gatedTeamRoles,
        connectInsightsDays: 3,
        connectFunnel: true,
      },
    },
    {
      planName: 'scale',
      featureSetId: 'efd795dc-0e02-4a35-95cb-16b1faf0a468',
      selfServe: false,
      features: {
        scheduledWebhooks: true,
        logDays: 30,
        logExport: true,
        logFilter: true,
        applications: 2,
        teams: false,
        teamsRoles: gatedTeamRoles,
        connectInsightsDays: 7,
        connectFunnel: true,
      },
    },
  ],
};
export default gatedFeatures;
