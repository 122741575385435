import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Initialization from './Initialization';
import { actions as applicationActions } from '../../services/applications/reducers';
import { actions as authActions } from '../../services/auth/reducers';
import { actions as billingActions } from '../../services/billing/reducers';
import { actions as onboardingActions } from '../../services/onboarding/reducers';

function mapStateToProps(state) {
  return {
    postAuthRedirect: state.auth.postAuthRedirect,
    showUserSetup: state.onboarding.showUserSetup,
  };
}

function mapDispatchToProps(dispatch) {
  const { initializeApplications } = applicationActions;
  const { setPostAuthRedirect } = authActions;
  const { initializeBilling } = billingActions;
  const { initializeOnboarding } = onboardingActions;

  return {
    actions: bindActionCreators({
      initializeApplications,
      initializeBilling,
      initializeOnboarding,
      setPostAuthRedirect,
    }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Initialization);
