import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { eventProps } from '../../../../propTypes';
import staticText from '../../../../../../../../localization/Application/ConnectInsights/connectOptions';

import { ConnectOptionsContainer, StyledAlert, StyledLink } from './styles';

const ConnectOptions = ({ newestEvent }) => {
  const theme = useTheme();

  return (
    <div>
      {staticText.labels.map((label) => {
        const paramValue = newestEvent[label.key];
        const noCustomerVehicleOwnerId = label.key === 'customerVehicleOwnerId' && !paramValue;
        return (
          <ConnectOptionsContainer key={label.key} hideBorder={noCustomerVehicleOwnerId}>
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ fontFamily: theme.typography.bold.fontFamily }}>
                {label.displayName}
              </Typography>
              {
                paramValue
                  ? (
                    <Typography>
                      {label.formatter
                        ? label.formatter(paramValue)
                        : paramValue
                      }
                    </Typography>
                  ) : (
                    <Typography sx={{ color: theme.palette.grey[500] }}>
                      {staticText.notAvailable}
                    </Typography>
                  )
              }
            </Box>
            {
              noCustomerVehicleOwnerId && (
                <StyledAlert
                  severity="info"
                >
                  <Typography display="inline">{staticText.userParamInfo}</Typography>
                  <StyledLink
                    href={staticText.learnMoreButton.link}
                  >
                    {staticText.learnMoreButton.text}
                  </StyledLink>
                </StyledAlert>
              )
            }
          </ConnectOptionsContainer>
        );
      })}
    </div>
  );
};

export default ConnectOptions;

ConnectOptions.propTypes = {
  newestEvent: eventProps,
};

ConnectOptions.defaultProps = {
  newestEvent: {},
};
