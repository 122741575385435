import React from 'react';
import { element, shape, string } from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@mui/material';

import { WebhooksStatusRow } from './components';
import staticText from '../../../../../../localization/Application/webhooks';

const WebhooksStatus = ({ webhooks, upgradeMessage }) => (
  <React.Fragment>
    {upgradeMessage}
    <TableContainer sx={{ mb: 4 }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '150px' }}>
              <Typography variant="h3">{staticText.webhooksTable.headers.status}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">{staticText.webhooksTable.headers.name}</Typography>
            </TableCell>
            <TableCell sx={{ width: '300px' }}>
              <Typography variant="h3">{staticText.webhooksTable.headers.actions}</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(webhooks).map(webhookId => (
            <WebhooksStatusRow
              key={webhookId}
              webhook={webhooks[webhookId]}
              disabled={!!upgradeMessage}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </React.Fragment>
);

export default WebhooksStatus;

WebhooksStatus.propTypes = {
  webhooks: shape({
    disabledReason: string,
    name: string,
    id: string,
    status: string,
  }).isRequired,
  upgradeMessage: element,
};

WebhooksStatus.defaultProps = {
  upgradeMessage: null,
};
