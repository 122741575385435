/* istanbul ignore file */

export const viewedCredentialsStorageKey = 'smartcar-simulator-viewed-credentials-vins';

export const generateVin = () => {
  let simVin = '1SC';
  const randomChars = 'ABCDEFGHJKLMNPRSTUVWXYZ0123456789'; // alphanumeric characters other than I, O and Q
  for (let i = 0; i < 14; i += 1) {
    simVin += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return simVin;
};

// Locally store the vehicle's VIN once the credentials drawer has been shown once,
// to prevent the drawer automatically opening again on future visits.
export const handleVinCredentials = (vin, setCredentialsDrawerOpen) => {
  const viewedCredentialsVins = JSON.parse(localStorage.getItem(viewedCredentialsStorageKey));
  if (!viewedCredentialsVins) {
    setCredentialsDrawerOpen(true);
    localStorage.setItem(viewedCredentialsStorageKey, JSON.stringify([vin]));
  } else if (viewedCredentialsVins.length > 0 && !viewedCredentialsVins.includes(vin)) {
    setCredentialsDrawerOpen(true);
    viewedCredentialsVins.push(vin);
    localStorage.setItem(viewedCredentialsStorageKey, JSON.stringify(viewedCredentialsVins));
  }
};

// Remove VIN from viewed credentials local storage
export const removeVinCredentialsStorage = (vin) => {
  const viewedCredentialsVins = JSON.parse(localStorage.getItem(viewedCredentialsStorageKey));
  const indexOfVin = viewedCredentialsVins.indexOf(vin);
  if (indexOfVin > -1) viewedCredentialsVins.splice(indexOfVin, 1);
  localStorage.setItem(viewedCredentialsStorageKey, JSON.stringify(viewedCredentialsVins));
};

export const isAutoCreatedVehicle = vin => vin.startsWith('2SCTESL');
