import _ from 'lodash';
import axios from 'axios';

// eslint-disable-next-line import/extensions
import appConfig from 'appConfig';
import { actions as authActions } from '../../services/auth/reducers';

const api = {};

const instance = axios.create({
  withCredentials: true,
  baseURL: _.get(appConfig, 'baseURL'),
});

instance.defaults.headers.common['SC-TOKEN'] = localStorage.getItem('accessToken');

const setToken = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    instance.defaults.headers.common['SC-TOKEN'] = accessToken;
  }
};

api.interceptor = (store) => {
  instance.interceptors.response.use(async (response) => {
    if (response.data && response.data.exchangeKey) {
      setToken(response.data.exchangeKey);
    } else {
      const accessToken = response.headers['sc-token'];
      setToken(accessToken);
    }
    return response;
  }, (err) => {
    if (err.response.data.error === 'authentication_error') {
      if (err.config.url !== '/developer/logout') {
        store.dispatch(authActions.logoutRequest(true));
      } else {
        // we will return err here to prevent the saga from throwing the error
        return err;
      }
    }
    return Promise.reject(err);
  });
};

api.axiosInstance = instance;

api.createOrganization = async (organizationName, dashboardUserId) => {
  const response = await instance.post('/organization', {
    name: organizationName,
    dashboardUserId,
  });
  return response;
};

api.fetchOrganizations = async () => {
  const response = await instance.get('/organization');
  return response;
};

/**
 * Temporary route to update the redis session data
 * to override developerId with the active organizationId
 * We can remove this route when processors no longer depend on developerId
 * in session cache for organizationId, which will be bigger change requiring routes
 * that perform org actions to be nested under /organization
 */
api.selectOrganization = async (organizationId) => {
  const response = await instance.put(`/organization/${organizationId}/select-organization`);
  return response;
};

api.updateOrgSettings = async (organizationId, updates) => {
  const response = await instance.put(`/organization/${organizationId}`, updates);
  return response;
};

api.fetchOrganizationApplications = async (organizationId) => {
  const response = await instance.get(`/organization/${organizationId}/applications`);
  return response;
};

api.fetchApiRequests = async (applicationId, filterValues) => {
  const response = await instance.get(
    `/applications/${applicationId}/metrics/api`,
    {
      params: filterValues,
    },
  );
  return response;
};

api.fetchResponseStatusSummary = async (applicationId, filterValues) => {
  const response = await instance.get(
    `/applications/${applicationId}/metrics/response-summary`,
    {
      params: filterValues,
    },
  );
  return response;
};

api.fetchMakesSummary = async (applicationId, filterValues) => {
  const response = await instance.get(
    `/applications/${applicationId}/metrics/makes`,
    {
      params: filterValues,
    },
  );
  return response;
};

// eslint-disable-next-line no-unused-vars
api.fetchRequestLogs = async (applicationId, filterValues) => {
  const response = await instance.get(
    `/applications/${applicationId}/requests-log`,
    {
      params: filterValues,
    },
  );

  return response;
};

api.fetchRequestsLogCsv = async (applicationId, filterValues) => {
  const response = await instance.get(
    `/applications/${applicationId}/requests-log/export`,
    {
      params: filterValues,
    },
  );

  return response;
};

api.registerApplication = async (newApplication) => {
  const response = await instance.post('/applications', newApplication);
  return response;
};

api.updateApplication = async (
  applicationId,
  updatedApplication,
) => {
  const updatedApplicationWithId = { id: applicationId, ...updatedApplication };
  const response = await instance.put(
    `/applications/${applicationId}`,
    updatedApplicationWithId,
  );
  return response;
};

api.deleteApplication = async (applicationId) => {
  const response = await instance.delete(`/applications/${applicationId}`);
  return response;
};

api.updateLockedApplications = async (lockedApplications) => {
  const response = await instance.patch('/applications/locked-applications/list', lockedApplications);
  return response;
};

api.fetchLockedApplications = async () => {
  const response = await instance.get('/applications/locked-applications/list');
  return response;
};

api.regenerateSecret = async (applicationId) => {
  const response = await instance.put(
    `/applications/${applicationId}/secret`,
    applicationId,
  );
  return response;
};

api.sendOrganizationInvite = async (
  organizationId,
  email,
  dashboardRoleName,
  organizationRoleName,
  applicationIds,
) => {
  const response = await instance.post(
    `/organization/${organizationId}/invitation`,
    {
      email,
      dashboardRoleName,
      organizationRoleName,
      applicationIds,
    },
  );
  return response;
};

api.verifyOrganizationInvite = async (invitationId, errorInvalidAccept) => {
  const response = await instance.put(
    '/organization-invitation/join',
    {
      id: invitationId,
      error: errorInvalidAccept,
    },
  );
  return response;
};

api.fetchActiveOrgMembers = async (organizationId) => {
  const response = await instance.get(
    `/organization/${organizationId}/members`,
  );
  return response;
};

api.fetchInvitedOrganizationMembers = async (organizationId) => {
  const response = await instance.get(
    `/organization/${organizationId}/invitations`,
  );
  return response;
};

api.fetchDashboardPermissionRoles = async () => {
  const response = await instance.get('/organization/userRoles');
  return response;
};

api.updateOrgMember = async (
  organizationId,
  dashboardUserId,
  selectedRole,
  organizationRole,
  applicationIds,
) => {
  const response = await
  instance.put(`/organization/${organizationId}/member/${dashboardUserId}`, {
    dashboardRole: selectedRole,
    organizationRole,
    applicationIds,
  });
  return response;
};

api.deleteOrganizationInvite = async (organizationId, invitationId) => {
  const response = await instance.delete(
    `/organization/${organizationId}/invitation/${invitationId}`,
  );
  return response;
};

api.deleteOrganizationMember = async (
  organizationId,
  dashboardUser,
) => {
  const response = await instance.delete(
    `/organization/${organizationId}/member/${dashboardUser.id}`,
    {
      params: {
        dashboardRole: dashboardUser.dashboardRole,
      },
    },
  );
  return response;
};

api.fetchWebhooks = async (applicationId) => {
  const response = await instance.get(
    `/applications/${applicationId}/webhooks`,
  );

  return response;
};

api.enableWebhook = async (
  applicationId,
  webhookId,
  challenge,
) => {
  const response = await instance.post(
    `/applications/${applicationId}/webhooks/${webhookId}/enable`,
    { challenge },
  );

  return response;
};

api.deleteWebhook = async (applicationId, webhookId) => {
  const response = await instance.delete(
    `/applications/${applicationId}/webhooks/${webhookId}`,
  );

  return response;
};

api.updateWebhook = async (applicationId, webhookId, updates) => {
  const response = await instance.put(
    `/applications/${applicationId}/webhooks/${webhookId}`,
    updates,
  );

  return response;
};

api.createWebhook = async (applicationId, webhook) => {
  const response = await instance.post(
    `/applications/${applicationId}/webhooks/`,
    webhook,
  );

  return response;
};

api.createWebhookInterest = async (applicationId, formData) => {
  const response = await instance.post(
    `/applications/${applicationId}/webhooks/beta-interest`,
    formData,
  );

  return response;
};

api.fetchWebhookTestEvent = async (applicationId, webhookId) => {
  const response = await instance.get(
    `/applications/${applicationId}/webhooks/${webhookId}/test`,
  );

  return response;
};

api.testWebhook = async (applicationId, webhookId, testEvent) => {
  const response = await instance.post(
    `/applications/${applicationId}/webhooks/${webhookId}/test`,
    testEvent,
  );

  return response;
};

api.regenerateManagementToken = async (applicationId) => {
  const response = await instance.put(
    `/applications/${applicationId}/token`,
    applicationId,
  );
  return response;
};

api.fetchOnboardingDetails = async () => {
  const response = await instance.get('/developer/onboarding');
  return response;
};

api.updateOnboardingEvent = async (eventName) => {
  const response = await instance.put('/developer/onboarding/event', {
    eventName,
  });
  return response;
};

api.updateBillingInfo = async (organizationId, updatedBillingInfo) => {
  const {
    currentPlan, planName, stripeCheckoutSessionId,
  } = updatedBillingInfo;
  const response = await instance.patch(
    `/organization/${organizationId}/billing`,
    {
      currentPlan,
      planName,
      stripeCheckoutSessionId,
    },
  );

  return response;
};

api.fetchStripeUrl = async (organizationId, type, payload) => {
  const response = await instance.post(`/organization/${organizationId}/billing`, { type, payload });
  return response.data;
};

api.fetchBillingInfo = async (organizationId, orgFeatureSetId) => {
  const response = await instance.get(`/organization/${organizationId}/billing`, { params: { orgFeatureSetId } });
  return response;
};

api.createDashboardUser = async (signUpInfo) => {
  const response = await instance.post(
    '/dashboard-user/',
    signUpInfo,
  );
  return response;
};

api.deleteDashboardUser = async (dashboardUserId) => {
  const response = await instance.delete(
    `dashboard-user/${dashboardUserId}`,
  );
  return response;
};

api.getLoginProvider = async (username) => {
  const response = await instance.get(
    '/developer/login-provider',
    {
      params: { username },
    },
  );
  return response;
};

api.authenticateDeveloper = async (loginInfo) => {
  const response = await instance.post(
    '/developer/login',
    loginInfo,
  );
  return response;
};

api.triggerOauthFlow = async (oauthData) => {
  const response = await instance.get(
    '/developer/initiate-o-auth',
    {
      params: oauthData,
    },
  );
  return response;
};

api.authenticateOAuthDeveloper = async (loginInfo) => {
  const response = await instance.post(
    '/developer/o-auth-login',
    loginInfo,
  );
  return response;
};

api.createOauthDeveloper = async (loginInfo) => {
  const response = await instance.post(
    '/developer/o-auth-signup',
    loginInfo,
  );

  return response;
};

api.logoutDeveloper = async () => {
  const response = await instance.post(
    '/developer/logout',
  );
  return response;
};

api.getDeveloper = async () => {
  const response = await instance.get('/developer');
  return response;
};

api.updateUser = async (updatedUser) => {
  const response = await instance.put('/developer', updatedUser);
  return response;
};

api.forgotPassword = async (username) => {
  const response = await instance.post(
    '/developer/request-password-reset',
    username,
  );
  return response;
};

api.resetPassword = async (payload) => {
  const { password } = payload.fields;
  const { authToken } = payload;
  const response = await instance.put(
    '/developer/password',
    { password, authToken },
  );
  return response;
};

api.sendVerificationEmail = async () => {
  const response = await instance.post(
    '/developer/request-email-verification',
  );
  return response;
};

api.verifyEmailToken = async (emailToken) => {
  const response = await instance.post(
    '/developer/verify-email-token',
    { emailToken },
  );
  return response;
};

api.sendSupportRequest = async (payload) => {
  const response = await instance.post(
    '/developer/support',
    payload,
  );
  return response;
};

api.verifyChatIdentity = async ({ chatId, email }) => {
  const response = await instance.post(
    '/developer/verify-chat-user',
    {
      chatId,
      email,
    },
  );
  return response;
};

api.fetchCompatibleVehicles = async (applicationId, region) => {
  const response = await instance.get(
    `/applications/${applicationId}/vehicle-simulator/compatibility/${region}`,
  );
  return response;
};

api.checkVinCompatibility = async (applicationId, vin) => {
  const response = await instance.post(
    `/applications/${applicationId}/vehicle-simulator/compatibility/${vin}`, { vin },
  );
  return response;
};

api.fetchVehicleCapabilities = async (applicationId, vehicleDefinitionId) => {
  const response = await instance.get(
    `/applications/${applicationId}/vehicle-simulator/capabilities/${vehicleDefinitionId}`,
  );
  return response;
};

api.fetchAllSimulatedVehicles = async (applicationId) => {
  const response = await instance.get(
    `/applications/${applicationId}/vehicle-simulator/vehicles`,
  );
  return response;
};

api.checkConnectedVehicle = async (applicationId, vin) => {
  const response = await instance.get(
    `/applications/${applicationId}/vehicle-simulator/vehicle/${vin}/status`,
  );
  return response;
};

api.createSimulatedVehicle = async (applicationId, vehicle) => {
  const response = await instance.post(
    `/applications/${applicationId}/vehicle-simulator/vehicle`,
    vehicle,
  );
  return response;
};

api.deleteSimulatedVehicle = async (applicationId, vehicleId) => {
  const response = await instance.delete(
    `/applications/${applicationId}/vehicle-simulator/vehicle/${vehicleId}`,
  );
  return response;
};

api.createVehicleTrip = async (applicationId, tripInformation) => {
  const {
    regionCode,
    vehicleInfo: {
      id, vin, type, make,
    },
    tripSettings: { tripType, tripSpeed, tripLatency },
  } = tripInformation;
  const response = await instance.post(
    `/applications/${applicationId}/vehicle-simulator/vehicle-trip`,
    {
      vehicleId: id,
      vin,
      vehicleType: type,
      regionCode,
      tripType,
      tripSpeed,
      tripLatency,
      make,
    },
  );
  return response;
};

api.updateVehicleTrip = async (applicationId, vehicleId, action, payload) => {
  const response = await instance.post(
    `/applications/${applicationId}/vehicle-simulator/vehicle-trip/${vehicleId}`,
    { action, payload },
  );
  return response;
};

api.getVehicleTrip = async (applicationId, vehicleTripId) => {
  const response = await instance.get(
    `/applications/${applicationId}/vehicle-simulator/vehicle-trip/${vehicleTripId}`,
  );
  return response;
};

api.getVehicleTripFrame = async (applicationId, tripValues, frameIndex) => {
  const response = await instance.get(
    `/applications/${applicationId}/vehicle-simulator/vehicle-trip/frame`,
    {
      params: { tripValues, frameIndex },
    },
  );
  return response;
};

api.fetchConnectConfig = async (applicationId) => {
  const response = await instance.get(`/applications/${applicationId}/connect-config`);
  return response;
};

api.fetchBrandManagementFilterOptions = async (applicationId) => {
  const response = await instance.get(`/applications/${applicationId}/connect-config/brand-management-filters`);
  return response;
};

api.updateOrCreateConnectConfig = async (applicationId, payload) => {
  const response = await instance.post(`/applications/${applicationId}/connect-config`, payload);
  return response;
};

api.updateAppLogo = async (applicationId, data) => {
  const formData = new FormData();
  formData.append('logo', data);

  const response = await instance.post(`/applications/${applicationId}/connect-config/logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

api.deleteAppLogo = async (applicationId, logoUrlToDelete) => {
  const response = await instance.post(`/applications/${applicationId}/connect-config/logo/delete`, {
    logoUrl: logoUrlToDelete,
  });
  return response;
};

api.fetchAllowedMakesByCountry = async (applicationId, country) => {
  const response = await instance.get(`applications/${applicationId}/connect-config/makes/${country}`);
  return response;
};

api.fetchApplicationMakes = async (applicationId) => {
  const response = await instance.get(`/applications/${applicationId}/makes`);
  return response;
};

// fetches all compatible makes and models regardless of region
api.fetchAllCompatibleVehicles = async (applicationId) => {
  const response = await instance.get(`/applications/${applicationId}/vehicle-management/compatible-vehicles`);
  return response;
};

api.fetchConnectedVehicles = async (applicationId, filterValues) => {
  const response = await instance.get(`/applications/${applicationId}/vehicle-management`,
    {
      params: filterValues,
    },
  );
  return response;
};

api.fetchVehicleLogs = async (applicationId, vehicleId, filterValues) => {
  const filters = {
    ...filterValues,
    vehicleId,
  };
  const response = await instance.get(`/applications/${applicationId}/requests-log`, {
    params: filters,
  });
  return response;
};

api.fetchVehicleOverview = async (applicationId, vehicleId, organizationId) => {
  const response = await instance.get(`/applications/${applicationId}/vehicle-management/vehicle/${vehicleId}/overview`,
    {
      params: { developerId: organizationId },
    },
  );
  return response;
};

api.disconnectVehicle = async (applicationId, vehicleId) => {
  const response = await instance.delete(`/applications/${applicationId}/vehicle-management/vehicle/${vehicleId}`);
  return response;
};

api.fetchConnectEvents = async (applicationId, filterValues) => {
  const response = await instance.get(`/applications/${applicationId}/connect-events`,
    {
      params: filterValues,
    },
  );
  return response;
};

api.fetchConnectFunnel = async (applicationId, filterValues) => {
  const response = await instance.get(`/applications/${applicationId}/connect-events/funnel`,
    {
      params: filterValues,
    },
  );
  return response;
};

export default api;
