import { Button, styled } from '@mui/material';
import { Link } from 'react-router-dom';


export const SelectorList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  padding: theme.spacing(1),
  width: `calc(100% - 2*${theme.spacing(1)})`,
  borderRadius: theme.border.radius,
  boxShadow: theme.border.boxShadow2,
  backgroundColor: theme.palette.common.white,
  cursor: 'default',
}));

export const AppLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(1),
  borderRadius: theme.border.radius,
  textDecoration: 'none',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textOverflow: 'ellipsis',

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
    textDecoration: 'none',
  },
}));

export const OrgLink = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.common.white,
  border: 'none',
  borderRadius: theme.border.radius,
  textAlign: 'left',

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
    textDecoration: 'none',
  },
}));

export const NewAppButton = styled(Button)(({ theme }) => ({
  justifyContent: 'flex-start',
  padding: theme.spacing(1, 0),
  width: '100%',

  '&:hover': {
    backgroundColor: theme.palette.grey[100],
    textDecoration: 'none',
  },

  svg: {
    marginTop: '-3px',
  },
}));

export const Divider = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  margin: theme.spacing(1, 0),
}));
