import React, { useEffect } from 'react';
import { arrayOf, bool, element, func, shape, string } from 'prop-types';
import { Box, Button } from '@mui/material';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import RegionList from './components/RegionList/RegionList';
import { Spinner, PermissionGate } from '../../../../../../components';
import { TabDescription, TabHeading } from '../../styles';
import staticText from '../../../../../../localization/Application/connect-config';
import { getEnabledCountriesByRegion } from '../../../../../../../config/countries';
import { useDelay } from '../../../../../../hooks';

const Countries = ({
  actions: {
    updateSelectedCountries,
    updateConnectConfigRequest,
  },
  connectConfig,
  selectedCountries,
  isFetchingConnectConfig,
  fetchingConnectConfigErrors,
  upgradeCta,
  upgradeMessage,
  renderErrorMessage,
  isUpdating,
}) => {
  const { afterDelay: showLoading, resetTimer } = useDelay();
  const handleSubmit = () => {
    resetTimer();
    updateConnectConfigRequest({
      supportedCountries: selectedCountries,
    });

    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: 'Connect Config - Country Management',
      form_content: {
        selectedCountries,
      },
    });
  };

  const noChangeToSelection = () => {
    if (!connectConfig) return false;
    if (connectConfig.supportedCountries &&
      connectConfig.supportedCountries.length !== selectedCountries.length) return false;

    const previousSelection = new Set(connectConfig.supportedCountries);
    return selectedCountries.every(country => previousSelection.has(country));
  };

  const isPending = isFetchingConnectConfig;
  const regions = getEnabledCountriesByRegion();
  const regionOrder = ['Americas', 'Europe', 'Asia'];

  const selectRegion = (region) => {
    let countries = [...selectedCountries];
    if (region) {
      regions[region].forEach((country) => {
        if (!countries.includes(country.code)) {
          countries.push(country.code);
        }
      });
    } else {
      countries = [];
      regionOrder.forEach((reg) => {
        regions[reg].forEach(country => countries.push(country.code));
      });
    }
    updateSelectedCountries(countries);
  };

  const clearRegion = (region) => {
    const countries = [...selectedCountries];
    const regionCountryCodes = regions[region].map(country => country.code);
    regionCountryCodes.forEach((country) => {
      const countryIndex = countries.indexOf(country);
      if (countryIndex > -1) {
        countries.splice(countryIndex, 1);
      }
    });
    updateSelectedCountries(countries);
  };

  // reset country selections on refresh to be consistent with other config tabs,
  // and if no supported countries config is found, set all countries as selected
  useEffect(() => {
    if (!connectConfig ||
      !connectConfig.supportedCountries ||
      !connectConfig.supportedCountries.length > 0
    ) {
      selectRegion();
    } else if (!isFetchingConnectConfig) {
      updateSelectedCountries(connectConfig.supportedCountries);
    }
  }, [connectConfig, isFetchingConnectConfig]);


  return (
    <div>
      <TabHeading variant="h2">{staticText.countries.heading}</TabHeading>
      {upgradeMessage}
      <TabDescription>{staticText.countries.description}</TabDescription>
      <div>
        {regionOrder.map(region => (
          <RegionList
            key={region}
            region={region}
            countries={regions[region]}
            selectedCountries={selectedCountries}
            selectRegion={selectRegion}
            clearRegion={clearRegion}
            updateSelectedCountries={updateSelectedCountries}
            isPending={isPending && showLoading}
          />
        ))}
      </div>
      {fetchingConnectConfigErrors.updatingConnectConfig &&
        renderErrorMessage(fetchingConnectConfigErrors.updatingConnectConfig)}
      <Box
        display="flex"
        justifyContent="flex-end"
        marginY={3}
      >
        {upgradeCta ||
          <PermissionGate
            dashboardPermission="write_connect_configuration"
          >
            <Button
              id="publish-button"
              variant="contained"
              onClick={handleSubmit}
              disabled={
                isPending ||
                !selectedCountries ||
                selectedCountries.length === 0 ||
                noChangeToSelection() ||
                !!upgradeMessage
              }
              sx={{ minHeight: '40px', minWidth: '152px' }}
            >
              {isUpdating && showLoading ? <Spinner size="button" additionalClassNames="flex" /> : staticText.publish}
            </Button>
          </PermissionGate>
        }
      </Box>
    </div>
  );
};

export default Countries;

Countries.propTypes = {
  actions: shape({
    updateConnectConfigRequest: func.isRequired,
    updateSelectedCountries: func.isRequired,
  }).isRequired,
  connectConfig: shape({
    supportedCountries: arrayOf(string).isRequired,
  }),
  fetchingConnectConfigErrors: shape({
    updatingConnectConfig: string,
  }),
  isFetchingConnectConfig: bool.isRequired,
  isUpdating: bool.isRequired,
  selectedCountries: arrayOf(string),
  upgradeCta: element,
  upgradeMessage: element,
  renderErrorMessage: func.isRequired,
};

Countries.defaultProps = {
  connectConfig: {},
  selectedCountries: [],
  fetchingConnectConfigErrors: {
    updatingConnectConfig: '',
  },
  upgradeCta: null,
  upgradeMessage: null,
};
