import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ExportButton from './ExportButton';
import { actions } from '../../../../../../reducers';

function mapStateToProps(state) {
  return {
    ...state.logs,
    featureSetId:
    state.applications.applications[state.applications.selectedApplication].featureSetId,
    rowData: state.logs.pageInfo.rows,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchExportData,
    setLogExportTriggered,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        fetchExportData,
        setLogExportTriggered,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportButton);
