import { styled } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const HomeLink = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  a: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    textDecoration: 'none',

    p: {
      fontFamily: theme.typography.bold.fontFamily,
    },

    svg: {
      marginRight: '4px',
      marginBottom: '-2px',
    },

    '&:hover': {
      textDecoration: 'underline',

      p: {
        color: theme.palette.secondary.main,
      },

      svg: {
        fill: theme.palette.secondary.main,
      },
    },
  },
}));
