import _ from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { reportToSegment, types as segmentTypes } from '@smartcar/morse';
import { push } from 'react-router-redux';
import staticText from '../../localization/Setup/account';

import api from '../api/api';
import { setSentryUserContext, sessionUtils } from './utils';
import { types, actions, selectors } from './reducers';
import { actions as errorActions } from '../errorReporter/reducers';
import { actions as onboardingActions } from '../onboarding/reducers';
import { actions as orgActions } from '../organizations/reducers';
import { Toast } from '../../components';

export function* getDeveloper() {
  try {
    const oauthFlowInvitationId = yield select(selectors.getOauthFlowInvitationId);
    const loginProvider = yield select(selectors.getLoginProvider);

    // This accepts invites for the Oauth flow. If an EmailPassword is
    // the login provider for an user, then this additional call to accept
    // the invite is not needed. This depends on if an invitationId is set
    // in the Join react component.
    let orgData = null;
    if (oauthFlowInvitationId && loginProvider === 'GoogleOAuth') {
      const { data } = yield call(api.verifyOrganizationInvite, oauthFlowInvitationId);
      orgData = data;
    }

    const { data } = yield call(api.getDeveloper);
    yield call(setSentryUserContext, data);
    yield call(
      reportToSegment,
      segmentTypes.IDENTIFY,
      data.dashboardUserId,
    );

    const showUserSetup = !data.industry || !data.jobTitle;
    yield put(onboardingActions.setShowUserSetup(showUserSetup));
    yield put(actions.getDeveloperSuccess({ data }));

    // This is portion of the oauth flow helps to set the newly accepted
    // org invite as the selected organization when landing on the dashboard.
    // Without this check, you will land on a different organization rather,
    // than the org you just accepted which is bad user experience.
    if (orgData && orgData.organizationId) {
      yield put(orgActions.selectOrganization(orgData.organizationId));
      yield put(push('/'));
    }
  } catch (error) {
    yield put(actions.getDeveloperFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* verifyEmailTokenRequest(action) {
  try {
    yield call(api.verifyEmailToken, action.payload);
    yield put(actions.verifyEmailTokenSuccess());
    yield call(getDeveloper);
    yield put(push('/'));
  } catch (error) {
    yield put(actions.verifyEmailTokenFailure(error));
    yield put(errorActions.reportError(error));
    yield put(push('/verify-email'));
  }
}
export function* getLoginProviderRequest(action) {
  try {
    const { data } = yield call(api.getLoginProvider, action.payload);

    let automaticallyLoginViaOauth = false;
    if (
      action.payload === data.username
      && data.loginProvider === 'GoogleOAuth'
    ) {
      automaticallyLoginViaOauth = true;
    }

    yield put(actions.getLoginProviderSuccess({
      loginProvider: data.loginProvider,
      automaticallyLoginViaOauth,
    }));
  } catch (error) {
    yield put(actions.getLoginProviderFailure(error));
    yield put(errorActions.reportError(error));
  }
}


export function* authenticateDeveloperRequest(action) {
  try {
    yield call(api.authenticateDeveloper, action.payload);
    yield call(getDeveloper);
    yield put(actions.authenticateDeveloperSuccess());
  } catch (error) {
    yield put(actions.authenticateDeveloperFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* triggerOauthFlowRequest(action) {
  try {
    // authFlow determines client side which oAuth flow
    // to trigger (create or login) after successfully
    // getting back the redirect url from the
    // `api.triggerOauthflow call` below
    const formattedPayload = _.omit(action.payload, 'authFlow');

    const { data } = yield call(api.triggerOauthFlow, formattedPayload);
    yield put(actions.triggerOauthFlowSuccess(data));
  } catch (error) {
    yield put(actions.triggerOauthFlowFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* authenticateOAuthRequest(action) {
  const prefilledInvitedUserName = yield select(selectors.getPrefilledInvitedUserName);

  const formattedPayload = {
    ...action.payload,
  };

  if (prefilledInvitedUserName) {
    formattedPayload.invitationEmail = prefilledInvitedUserName.trim().toLowerCase();
  }

  try {
    yield call(api.authenticateOAuthDeveloper, formattedPayload);
    yield call(getDeveloper);
    yield put(actions.authenticateDeveloperSuccess());
  } catch (error) {
    yield put(actions.authenticateDeveloperFailure(error));
    yield put(errorActions.reportError(error));
    yield put(actions.resetOauthInvitation());
  }
}

export function* createOauthRequest(action) {
  const prefilledInvitedUserName = yield select(selectors.getPrefilledInvitedUserName);

  const formattedPayload = {
    ...action.payload,
  };

  if (prefilledInvitedUserName) {
    formattedPayload.invitationEmail = prefilledInvitedUserName.trim().toLowerCase();
  }

  try {
    const { data } = yield call(api.createOauthDeveloper, formattedPayload);
    yield put(actions.createOauthSuccess());
    yield put(actions.setIsNewSignUp(true));

    // After sign-up we will use exchangeKey to initiate login
    yield call(authenticateOAuthRequest, {
      payload: {
        authToken: data.exchangeKey,
      },
    });
  } catch (error) {
    yield put(actions.createOauthFailure(error));
    yield put(errorActions.reportError(error));
    yield put(actions.resetOauthInvitation());
  }
}

export function* sendVerificationEmailRequest() {
  try {
    yield call(api.sendVerificationEmail);
    yield put(actions.sendVerificationEmailSuccess());
  } catch (error) {
    yield put(actions.sendVerificationEmailFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* createDashboardUser(action) {
  const formattedPayload = {
    ...action.payload,
    username: action.payload.username.trim().toLowerCase(),
  };
  try {
    const { data } = yield call(
      api.createDashboardUser,
      formattedPayload,
    );
    yield put(actions.createDashboardUserSuccess());
    yield put(actions.setIsNewSignUp(true));

    const isEmailLinkSignup = Boolean(yield select(selectors.getPostAuthRedirect));

    // After sign-up we will use exchangeKey to initiate login
    yield call(authenticateDeveloperRequest, {
      payload: {
        authToken: data.exchangeKey,
      },
    });

    // send verification email if email hasn't been verified yet and user isn't
    // signing up from an application invite link
    if (!data.emailVerifiedAt && !isEmailLinkSignup) {
      yield call(sendVerificationEmailRequest);
    }
  } catch (error) {
    yield put(actions.createDashboardUserFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* forgotPasswordRequest(action) {
  try {
    yield call(api.forgotPassword, action.payload);
    yield put(actions.forgotPasswordSuccess());
  } catch (error) {
    yield put(actions.forgotPasswordFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* resetPasswordRequest(action) {
  try {
    yield call(api.resetPassword, action.payload);
    yield put(actions.resetPasswordSuccess());
  } catch (error) {
    yield put(actions.resetPasswordFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* logoutRequest(action) {
  const redirect = action.payload;
  try {
    yield call(api.logoutDeveloper);
    yield call(sessionUtils.clearLocalStorage);
    yield call(reportToSegment, segmentTypes.RESET);
    yield put(actions.logoutSuccess());
    if (redirect) yield put(push('/login'));
  } catch (error) {
    yield put(actions.logoutFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* deleteDashboardUser() {
  const { dashboardUserId } = yield select(selectors.getUserContext);
  try {
    yield call(
      api.deleteDashboardUser,
      dashboardUserId,
    );
    yield put(actions.deleteDashboardUserSuccess());
    yield call(Toast, staticText.accountDeletionSuccess, 'success');

    // should mirror logout process
    yield call(sessionUtils.clearLocalStorage);
    yield call(reportToSegment, segmentTypes.RESET);
    yield put(actions.logoutSuccess());
    yield put(push('/signup'));
  } catch (error) {
    yield put(actions.deleteDashboardUserFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* updateUserRequest(action) {
  const formattedPayload = { ...action.payload };
  if (action.payload.username) {
    formattedPayload.username = action.payload.username.trim().toLowerCase();
  }

  try {
    const updatedUserResponse = yield call(
      api.updateUser,
      formattedPayload,
    );
    yield put(actions.updateUserSuccess(updatedUserResponse.data));
  } catch (error) {
    yield put(actions.updateUserFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(types.CREATE_DASHBOARD_USER_REQUEST, createDashboardUser);
  yield takeEvery(types.DELETE_DASHBOARD_USER_REQUEST, deleteDashboardUser);
  yield takeEvery(types.AUTHENTICATE_DEVELOPER_REQUEST, authenticateDeveloperRequest);
  yield takeEvery(types.TRIGGER_OAUTH_FLOW_REQUEST, triggerOauthFlowRequest);
  yield takeEvery(types.AUTHENTICATE_OATH_REQUEST, authenticateOAuthRequest);
  yield takeEvery(types.CREATE_OATH_REQUEST, createOauthRequest);
  yield takeEvery(types.GET_LOGIN_PROVIDER_REQUEST, getLoginProviderRequest);
  yield takeEvery(types.FORGOT_PASSWORD_REQUEST, forgotPasswordRequest);
  yield takeEvery(types.RESET_PASSWORD_REQUEST, resetPasswordRequest);
  yield takeEvery(types.LOGOUT_REQUEST, logoutRequest);
  yield takeEvery(types.UPDATE_USER_REQUEST, updateUserRequest);
  yield takeEvery(types.SEND_VERIFICATION_EMAIL_REQUEST, sendVerificationEmailRequest);
  yield takeEvery(types.VERIFY_EMAIL_TOKEN_REQUEST, verifyEmailTokenRequest);
}
