import { startCase } from 'lodash';
import { getCountryDisplayName } from '../../../../config/countries';
import { getLanguageDisplayName } from '../../../../config/languages';

const staticText = {
  labels: [
    {
      key: 'customerVehicleOwnerId',
      displayName: 'User',
    },
    {
      key: 'mode',
      displayName: 'Mode',
      formatter: startCase,
    },
    {
      key: 'launchParamSingleSelect',
      displayName: 'Single Select',
      formatter: startCase,
    },
    {
      key: 'country',
      displayName: 'Country',
      formatter: getCountryDisplayName,
    },
    {
      key: 'language',
      displayName: 'Language',
      formatter: getLanguageDisplayName,
    },
  ],
  notAvailable: 'n/a',
  userParamInfo: 'Specify a unique identifier for the vehicle owner to track and aggregate analytics across Connect sessions for each vehicle owner on Dashboard. ',
  learnMoreButton: {
    text: 'Learn more',
    link: 'https://smartcar.com/docs/connect/redirect-to-connect',
  },
};

export default staticText;
