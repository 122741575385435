const applicationNav = [
  {
    text: 'overview',
  },
  {
    text: 'connect',
  },
  {
    text: 'simulator',
  },
  {
    text: 'vehicles',
  },
  {
    text: 'logs',
  },
  {
    text: 'webhooks',
  },
  {
    text: 'configuration',
  },
];

// will become the primary nav once the LD flag is removed
const connectInsightsNav = [
  {
    text: 'overview',
  },
  {
    text: 'connect',
    subNav: [
      {
        text: 'customizations',
      },
      {
        text: 'insights',
        newfeature: true,
      },
    ],
  },
  {
    text: 'simulator',
  },
  {
    text: 'vehicles',
  },
  {
    text: 'logs',
  },
  {
    text: 'webhooks',
  },
  {
    text: 'configuration',
  },
];

const teamNav = [
  {
    text: 'settings',
  },
  {
    text: 'applications',
  },
  {
    text: 'members',
  },
  {
    text: 'billing',
  },
];

const topbarNav = {
  settings: {
    text: 'settings',
    path: '/team/settings',
    iconSrc: '/assets/icons/nav/settings.svg',
  },
  docs: {
    text: 'documentation',
    path: 'https://smartcar.com/docs',
    iconSrc: '/assets/icons/nav/book.svg',
  },
  support: {
    text: 'support',
    iconSrc: '/assets/icons/nav/support.svg',
  },
};

const accountMenu = {
  accountSettings: 'Account settings',
  cookieSettings: 'Cookie settings',
  logout: 'Log out',
  privacy: {
    linkText: 'Privacy policy',
    link: 'https://smartcar.com/privacy',
  },
  terms: {
    linkText: 'Terms of service',
    link: 'https://smartcar.com/terms',
  },
};

export { topbarNav, applicationNav, connectInsightsNav, teamNav, accountMenu };
