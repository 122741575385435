import React, { useEffect } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { get } from 'lodash';
import appConfig from 'appConfig';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import PreviewHeader from './components/PreviewHeader';
import { getCountrySelectorMenuOptions } from '../../../../../../../config/countries';

const previewPagePathMap = {
  preamble: '/preamble',
  brandSelector: '/brand-selector',
  login: '/login',
  grant: '/grant',
};

const Preview = styled('div', {
  shouldForwardProp: prop => prop !== 'darkmode',
})(({ darkmode, displayHeight }) => ({
  position: 'relative',

  '#connect-preview-background': {
    width: '360px',
    height: `${displayHeight}px`,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#F9FDFF',
    borderRadius: '20px',
    ...(darkmode && {
      backgroundColor: '#151D20',
    }),
  },

  '& iframe': {
    position: 'relative',
    border: 'none',
    borderRadius: '20px',
    boxShadow: '0px 0px 10px rgba(142, 142, 142, 0.5)',
  },
}));

const ConnectPreview = ({
  actions: {
    fetchMakesByCountryRequest,
    updatePreviewSettings,
  },
  application,
  previewPage,
  previewSettings: {
    selectedCountry = 'US',
    selectedSortOrder,
    selectedBranding,
    selectedLogo,
    selectedAppName,
    selectedTheme,
  },
  selectedMakes,
  selectedCountries,
  makesByCountry,
  showHeader,
  displayHeight,
}) => {
  const baseUrl = get(appConfig, 'connectPreviewURL');
  const path = previewPagePathMap[previewPage];

  // query params
  const clientId = `client_id=${application.id}`;
  const appName = `&app_name=${selectedAppName || application.name}`;
  // Filter the selected makes to only those supported in the selected country.
  // Makes query param must be an array with each make wrapped in double quotes.
  const makes = `&makes=[${selectedMakes
    .filter(make => makesByCountry.includes(make))
    .map(make => `"${make}"`)}]`;
  const sortOrder = selectedSortOrder ? `&sort_order=${selectedSortOrder}` : '';
  // Countries query param must be an array with each country code wrapped in double quotes.
  const countries = `&countries=[${selectedCountries.map(country => `"${country}"`)}]`;
  const country = `&country=${selectedCountry || 'US'}`;
  const branding = selectedBranding ? `&branding=${selectedBranding}` : '';
  const logo = selectedLogo ? `&logo=${selectedLogo}` : '';
  const theme = `&theme=${selectedTheme}`;

  const iframeSrc =
    `${baseUrl}${path}?${clientId}${appName}${makes}${sortOrder}${countries}${country}${theme}${branding}${logo}`;

  const handleChange = (e) => {
    const newCountry = e.target.value;
    updatePreviewSettings({ selectedCountry: newCountry });
    reportToSegment(types.TRACK, eventNames.dropdownClosed, {
      label: 'select',
      text: `[connect preview] country selection: ${newCountry}`,
    });
    fetchMakesByCountryRequest(newCountry);
  };

  useEffect(() => {
    // if selected countries list does not include the current selected country or the default 'US',
    // fallback to displaying the first country in the selected countries list
    if (!selectedCountries.includes(selectedCountry)) {
      updatePreviewSettings({ selectedCountry: selectedCountries[0] });
    }
    fetchMakesByCountryRequest(selectedCountry);
  }, []);

  const darkModeEnabled = () => {
    return selectedTheme === 'dark' ||
    (selectedTheme === 'system' && window.matchMedia
       && window.matchMedia('(prefers-color-scheme: dark)').matches);
  };

  const getCountryOptions = () => {
    const options = getCountrySelectorMenuOptions();
    return selectedCountries && selectedCountries.length > 0 ?
      options.filter(option => selectedCountries.includes(option.code)) :
      options;
  };

  return (
    <Box marginLeft={4}>
      {showHeader && (<PreviewHeader
        countryOptions={getCountryOptions()}
        handleChange={handleChange}
        selectedCountry={selectedCountry}
      />)}
      <Preview darkmode={darkModeEnabled()} displayHeight={displayHeight}>
        <div id="connect-preview-background" />
        <iframe
          id="connect-preview"
          title="connect preview"
          width={360}
          height={displayHeight}
          src={iframeSrc}
        />
      </Preview>
    </Box>
  );
};

export default ConnectPreview;

ConnectPreview.propTypes = {
  actions: shape({
    fetchMakesByCountryRequest: func,
    updatePreviewSettings: func,
  }).isRequired,
  application: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  previewPage: string.isRequired,
  previewSettings: shape({
    selectedSortOrder: string.isRequired,
    selectedAppName: string,
    selectedTheme: string.isRequired,
    selectedCountry: string.isRequired,
    selectedBranding: string.isRequired,
    selectedLogo: string.isRequired,
  }),
  selectedMakes: arrayOf(string),
  selectedCountries: arrayOf(string),
  makesByCountry: arrayOf(string),
  showHeader: bool,
  displayHeight: number,
  fetchMakesByCountryRequest: func.isRequired,
};

ConnectPreview.defaultProps = {
  selectedMakes: [],
  selectedCountries: [],
  makesByCountry: [],
  previewSettings: {
    selectedSortOrder: 'popularity',
    selectedAppName: undefined,
    selectedTheme: 'light',
    selectedCountry: 'US',
    selectedBranding: 'default',
    selectedLogo: false,
  },
  showHeader: false,
  displayHeight: 640,
};
