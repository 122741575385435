import FEATURES from '../../services/featureGate/features';

const staticText = {
  [FEATURES.APPLICATIONS]: {
    body: 'You have reached the limit for the number of apps you can configure. Upgrade your plan to register more apps.',
  },
  [`${FEATURES.APPLICATIONS}-locked`]: {
    body: "Your access to this application has been restricted due to your plan's features. Upgrade your plan to gain access to this application.",
  },
  [FEATURES.LOG_EXPORT]: {
    body: 'Upgrade your plan or contact us to learn more about how you can export vehicle logs.',
  },
  [FEATURES.EVENT_WEBHOOKS]: {
    body: 'Upgrade your plan or contact us to learn more about how you can get access to Event based webhooks.',
  },
  [FEATURES.CONNECT_CONFIG]: {
    body: 'Your plan does not support customizing Connect. Upgrade your plan to customize your customer experience & more.',
  },
  [FEATURES.CONNECT_FUNNEL]: {
    body: 'Upgrade your plan to unlock conversion rates and drop-off metrics.',
  },
  [FEATURES.SCHEDULED_WEBHOOKS]: {
    body: 'Your plan does not support adding webhooks. Upgrade your plan to add webhooks & more.',
  },
  [FEATURES.VEHICLE_SIMULATOR]: {
    body: 'Your plan does not support adding simulated vehicles. Upgrade your plan to test with simulated vehicles & more.',
  },
  title: 'Upgrade your plan',
  title2: 'Upgrade to unlock',
  upgrade: 'Upgrade now',
  decline: 'No thanks',
};

export default staticText;

