import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CreateVehicle from './CreateVehicle';
import { actions } from '../../reducers';

function mapStateToProps(state) {
  return {
    ...state.simulator,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    cancelFetching,
    createVehicle,
    fetchVinCompatibility,
    fetchVehicleCapabilities,
    setSelectedRegion,
    setSelectedVehicle,
  } = actions;

  return {
    actions: bindActionCreators(
      {
        cancelFetching,
        createVehicle,
        fetchVinCompatibility,
        fetchVehicleCapabilities,
        setSelectedRegion,
        setSelectedVehicle,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateVehicle);
