import React, { useEffect, useState } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import {
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';

import { applicationNav, connectInsightsNav, teamNav } from '../../../../localization/Application/nav';
import { Nav, NavToggle, NavItem, SubNavWrapper, HoverText } from './styles';
import { Toast } from '../../../../components';
import { capitalizeFirstLetter } from '../../utils';
import {
  AnchorIcon,
  AppsIcon,
  BillingIcon,
  CubeIcon,
  CustomizationsIcon,
  HomeIcon,
  KeyIcon,
  LogsIcon,
  SettingsIcon,
  SmartphoneIcon,
  TeamIcon,
  VehicleIcon,
} from '../../../../assets/icons/nav';

const iconMap = {
  applications: <AppsIcon />,
  billing: <BillingIcon />,
  configuration: <KeyIcon />,
  connect: <SmartphoneIcon />,
  customizations: <CustomizationsIcon />,
  logs: <LogsIcon />,
  members: <TeamIcon />,
  overview: <HomeIcon />,
  settings: <SettingsIcon />,
  simulator: <CubeIcon />,
  webhooks: <AnchorIcon />,
  vehicles: <VehicleIcon />,
};

const getSubNavLink = (
  billingWarning,
  expanded,
  getNavLink,
  handleNavLinkClick,
  parentText,
  path,
  pathParts,
  closeMenu,
) => (subNavItem) => {
  const Component = expanded ? NavItem : MenuItem;
  const subNavPath = getNavLink(`${parentText}/${subNavItem.text}`);
  const handleSubNavLinkClick = (e) => {
    handleNavLinkClick(e, subNavItem, path);
    if (closeMenu) {
      closeMenu();
    }
  };

  return (
    <Component
      key={subNavItem.text}
      className={`${expanded ? 'sub-nav ' : ''}text`}
      newfeature={subNavItem.newfeature}
      current={pathParts.has(subNavItem.text.toLocaleLowerCase())}
      error={billingWarning && subNavItem.text === 'billing'}
      {...(!expanded && { component: NavItem })}
    >
      <Link
        to={subNavPath}
        onClick={handleSubNavLinkClick}
      >
        <Typography>{capitalizeFirstLetter(subNavItem.text)}</Typography>
      </Link>
    </Component>
  );
};

const SidebarNav = ({
  applicationId,
  pathname,
  menuExpanded,
  toggleExpandedMenu,
  view,
  authErrorMessage,
  billingInfo,
}) => {
  const theme = useTheme();
  const [subNavAnchorEl, setSubNavAnchorEl] = useState(null);
  const pathParts = new Set(pathname.split('/'));

  const { connectInsights } = useFlags();
  // eslint-disable-next-line no-nested-ternary
  const navItems = view === 'team' ? teamNav : (
    connectInsights ? connectInsightsNav : applicationNav
  );

  const getNavLink = (path) => {
    return view === 'team' ?
      `/team/${path}` :
      `/apps/${applicationId}/${path}`;
  };

  const handleNavLinkClick = (e, text, path) => {
    // we also have a click listener on the nav container itself to toggle
    // expanding the nav, so this prevents that from firing when a link is clicked
    e.stopPropagation();
    reportToSegment(types.TRACK, eventNames.linkClicked, {
      section: 'sidebar', style: 'inline', path, text,
    });
  };

  const billingWarning = Boolean(billingInfo.vehiclesOverLimit) ||
    Boolean(billingInfo.numOfApiViolationVehicles) ||
    Boolean(billingInfo.delinquent);

  useEffect(() => {
    if (authErrorMessage) {
      Toast(authErrorMessage, 'warn');
    }
  }, [authErrorMessage]);

  return (
    <Nav aria-label="secondary" expanded={menuExpanded} onClick={toggleExpandedMenu}>
      <NavToggle
        type="button"
        tabIndex={0}
        onClick={toggleExpandedMenu}
        aria-expanded={menuExpanded}
        menuExpanded={menuExpanded}
      >
        <NavigateNextRoundedIcon htmlColor={theme.palette.grey[600]} />
      </NavToggle>
      <ul>
        {navItems.map(({
          text, icon, newfeature, subNav,
        }) => {
          const path = getNavLink(text);
          return (
            <React.Fragment>
              <NavItem
                key={text}
                className={menuExpanded ? 'text' : 'icon'}
                newfeature={newfeature}
                current={pathParts.has(text.toLocaleLowerCase())}
                error={billingWarning && text === 'billing'}
              >
                {(!subNav || menuExpanded) ? (
                  <Link to={path} onClick={e => handleNavLinkClick(e, text, path)}>
                    {iconMap[text] ? iconMap[text] : <img src={icon} alt="" />}
                    {menuExpanded && <Typography>{capitalizeFirstLetter(text)}</Typography>}
                    {!menuExpanded && (
                      <HoverText className="hover-text">
                        {capitalizeFirstLetter(text)}
                      </HoverText>
                    )}
                  </Link>
                ) : (
                  <React.Fragment>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSubNavAnchorEl(e.currentTarget);
                      }}
                    >
                      {iconMap[text] ? iconMap[text] : <img src={icon} alt="" />}
                      <HoverText className="hover-text">
                        {capitalizeFirstLetter(text)}
                      </HoverText>
                    </button>
                    <Menu
                      anchorEl={subNavAnchorEl}
                      anchorOrigin={{
                        horizontal: 'right',
                      }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                      open={Boolean(subNavAnchorEl)}
                      onClose={(e) => {
                        e.stopPropagation();
                        setSubNavAnchorEl(null);
                      }}
                      sx={{
                        '.MuiList-root': {
                          padding: theme.spacing(0.5, 1.5),
                          width: '140px',
                        },
                        marginLeft: '11px',
                      }}
                      onClick={e => e.stopPropagation()}
                    >
                      {subNav.map(
                        getSubNavLink(
                          billingWarning,
                          menuExpanded,
                          getNavLink,
                          handleNavLinkClick,
                          text,
                          path,
                          pathParts,
                          () => setSubNavAnchorEl(null),
                        ),
                      )}
                    </Menu>
                  </React.Fragment>
                )}
              </NavItem>
              {/* subnav list items when sidebar is expanded */}
              {subNav && menuExpanded && (
                <SubNavWrapper>
                  {subNav.map(
                    getSubNavLink(
                      billingWarning,
                      menuExpanded,
                      getNavLink,
                      handleNavLinkClick,
                      text,
                      path,
                      pathParts,
                    ),
                  )}
                </SubNavWrapper>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </Nav>
  );
};

export default SidebarNav;

SidebarNav.propTypes = {
  applicationId: string.isRequired,
  pathname: string.isRequired,
  menuExpanded: bool.isRequired,
  toggleExpandedMenu: func.isRequired,
  view: string.isRequired,
  authErrorMessage: string,
  billingInfo: shape({
    vehiclesOverLimit: number.isRequired,
    numOfApiViolationVehicles: number,
  }).isRequired,
};

SidebarNav.defaultProps = {
  authErrorMessage: '',
};
