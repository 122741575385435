const staticText = {
  vehicleId: 'Vehicle ID',
  vin: 'VIN',
  tripTypeLabel: 'Trip type',
  vehicleStateLabel: 'Vehicle state',
  clockLabel: 'Trip time elapsed',
  distanceLabel: 'Distance driven',
  timelineHeading: 'Trip Progress',
  endpointsHeading: 'Current Vehicle State',
  tripOptions: {
    unitSystemDropdown: {
      label: 'Unit System',
      tooltip: 'In order to receive data in imperial units through your application, you will need to set the SC-Unit-System request header to configure your preferred unit system.',
    },
    tripTypeDropdown: {
      label: 'Trip Type',
    },
    responseLatencyDropdown: {
      label: 'Response Latency',
      tooltip: "With response latency set to 'Realistic' your application will experience delays in API response times similar to those experienced in production with real vehicle brands. Setting response latency to 'Immediate' bypasses those expected API response delays for a faster testing experience.",
    },
    speedDropdown: {
      label: 'Speed',
      tooltip: 'Speed up or slow down the trip playback speed depending on how quickly you prefer the vehicle data to update.',
    },
  },
  credentialsDrawerButton: 'Connect Credentials',
  credentialsDrawerContent: {
    autoplayTitle: 'Start testing right away',
    autoplayText: 'This simulated Tesla Model 3 has been created for your application to quickly start testing API requests. The vehicle is available for requests around the clock and spends time in various states including driving, parked, and charging. Once connected, you can begin making API requests to any of the endpoints displayed here and more. Upgrade your account to unlock additional simulator features like trip timeline controls, play speed, and creating additional simulated vehicles.',
    title1: 'Connect the vehicle to your app',
    text1: 'You will need to connect the simulated vehicle to your application in order to begin making API requests to the vehicle. To connect the vehicle, launch the Smartcar Connect authorization flow in `simulated` mode from your app using one of our SDKs. Check out the tutorial for a step-by-step walkthrough |+|here@@https://smartcar.com/docs/tutorials/react|+|.',
    callout1: 'Note: To launch the Connect flow in simulated mode, be sure to set the query parameter `mode` to `simulated`.',
    title2: 'Credentials',
    text2: "Once you've launched the Smartcar Connect flow in `simulated` mode, select the OEM of your simulated vehicle and log in using the simulated credentials generated for you below.",
    emailLabel: 'Email',
    passwordLabel: 'Password',
    footnote: 'Note: It may take up to a minute once you complete the Connect flow to see the connection reflected here in the dashboard.',
  },
  latencyDrawerButton: 'View average API request latencies',
  latencyDrawerTitle: brand => `${brand} API estimated latencies`,
  brandLatencies: {
    heading: 'Estimated request latencies',
    footnote: {
      text: 'Read about request latencies ',
      cta: {
        text: 'here.',
        link: 'https://smartcarapi.notion.site/Brand-Quirks-and-Latencies-c387c8432f9644dab6c72d74515f57ab',
      },
    },
    endpoint: 'Endpoint',
    method: 'Method',
    range: 'Range',
    median: 'Median',
  },
  deleteVehicleDialogButton: 'Delete vehicle',
  deleteVehicleDialog: {
    heading: 'Delete vehicle',
    text: vehicle => `Are you sure you want to delete your ${vehicle}? This will also delete any trip progress for this vehicle.`,
    submitButtonText: 'Delete vehicle',
  },
  changeTripDialog: {
    heading: 'Change trip type',
    text: 'Are you sure you want to change the trip type? All current trip progress will be lost.',
    submitButtonText: 'Change trip type',
  },
};

export default staticText;
