import { call, put, select, takeEvery } from 'redux-saga/effects';

import { selectors as applicationSelectors } from '../../../../services/applications/reducers';
import { actions, types } from './reducers';

import api from '../../../../services/api/api';
import { Toast } from '../../../../components';

export function* fetchWebhooksRequest() {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.fetchWebhooks,
      applicationId,
    );

    yield put(actions.fetchWebhooksSuccess(data));
  } catch (err) {
    yield put(actions.fetchWebhooksFailure(err.response.data));
  }
}

export function* verifyWebhookRequest(action) {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { meta: webhookId, payload: challenge } = action;

    const { data } = yield call(
      api.enableWebhook,
      applicationId,
      webhookId,
      challenge,
    );

    yield put(actions.verifyWebhookSuccess(webhookId, data));
  } catch (err) {
    yield put(actions.verifyWebhookFailure(err.response.data));
  }
}

export function* deleteWebhookRequest(action) {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    yield call(
      api.deleteWebhook,
      applicationId,
      action.meta,
    );
    yield put(actions.deleteWebhookSuccess(action.meta));
  } catch (error) {
    yield put(actions.deleteWebhookFailure(error.response.data));
  }
}

export function* createWebhookRequest(action) {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(
      api.createWebhook,
      applicationId,
      action.payload,
    );
    yield put(actions.createWebhookSuccess(data));
  } catch (error) {
    yield put(actions.createWebhookFailure(error.response.data));
  }
}

export function* createWebhookInterestRequest(action) {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    yield call(
      api.createWebhookInterest,
      applicationId,
      action.payload,
    );
    localStorage.setItem('webhookInterest', true);
    yield call(Toast, 'Got it! We’ll notify you via email when Dynamic webhooks are available.', 'success', Toast.POSITION.TOP_CENTER);
    yield put(actions.createWebhookInterestSuccess());
  } catch (error) {
    yield call(Toast, error.response.data && error.response.data.message, 'warn');
    yield put(actions.createWebhookInterestFailure(error.response.data));
  }
}

export function* updateWebhookRequest(action) {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    const { data } = yield call(
      api.updateWebhook,
      applicationId,
      action.meta,
      action.payload,
    );
    yield put(actions.updateWebhookSuccess(action.meta, data));
  } catch (error) {
    yield put(actions.updateWebhookFailure(error.response.data));
  }
}

export function* fetchTestEventRequest(action) {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    const { data } = yield call(
      api.fetchWebhookTestEvent,
      applicationId,
      action.meta,
    );
    yield put(actions.fetchTestEventSuccess(data));
  } catch (error) {
    yield put(actions.fetchTestEventFailure(error));
  }
}

export function* testWebhookRequest(action) {
  try {
    const applicationId = yield select(applicationSelectors.getSelectedApplication);
    const { data } = yield call(
      api.testWebhook,
      applicationId,
      action.meta,
      action.payload,
    );
    yield put(actions.testWebhookSuccess(data));
  } catch (error) {
    yield put(actions.testWebhookFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(types.FETCH_WEBHOOKS_REQUEST, fetchWebhooksRequest);
  yield takeEvery(types.VERIFY_WEBHOOK_REQUEST, verifyWebhookRequest);
  yield takeEvery(types.DELETE_WEBHOOK_REQUEST, deleteWebhookRequest);
  yield takeEvery(types.CREATE_WEBHOOK_REQUEST, createWebhookRequest);
  yield takeEvery(types.UPDATE_WEBHOOK_REQUEST, updateWebhookRequest);
  yield takeEvery(types.FETCH_TEST_EVENT_REQUEST, fetchTestEventRequest);
  yield takeEvery(types.TEST_WEBHOOK_REQUEST, testWebhookRequest);
  yield takeEvery(types.CREATE_WEBHOOK_INTEREST_REQUEST, createWebhookInterestRequest);
}
