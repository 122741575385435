const staticText = {
  title: 'Select a plan',
  plans: [
    {
      planName: 'Free',
      price: {
        cost: '$0',
      },
      planSubtitle: 'No credit card required',
      planHighlights: [
        'up to 3 vehicles included',
        '300 API calls per vehicle',
        '1 pre-created simulated vehicle',
        '1 day of log data',
        '1 application',
      ],
      planFeatures: {
        title: 'Get started with:',
        features: [
          {
            text: 'Standard APIs',
            tooltip:
              'Retrieve vehicle data and trigger actions through simple API requests',
          },
          {
            text: 'Standard connect flow',
            tooltip: 'Access to consent management and password reset flows',
          },
          {
            text: '1-day request logs',
            tooltip: 'Access to all vehicle data requests for a 24 hour period',
          },
          {
            text: 'List of vehicles',
            tooltip:
              'Access to all vehicles by VIN that have onboarded to your app',
          },
          {
            text: '1 dashboard app',
            tooltip:
              'One application workspace to use within the Smartcar dashboard',
          },
          {
            text: 'Email support',
          },
        ],
      },
    },
    {
      planName: 'Build',
      price: {
        cost: '$19.99',
        addendum: '/month',
      },
      planSubtitle: '$7/month per additional vehicle',
      planHighlights: [
        '3 vehicles included, up to 20',
        '600 API calls per vehicle',
        '2 applications',
        'Full access to simulated vehicles',
        '7 days of log data',
      ],
      planFeatures: {
        title: 'Everything in Free, plus:',
        features: [
          {
            text: 'Make-specific APIs',
            tooltip: 'Retrieve vehicle data for specific makes/OEMs',
          },
          {
            text: 'Vehicle simulator',
            tooltip: 'Simulate trips and vehicle behavior within the dashboard',
          },
          {
            text: '7-day request logs',
            tooltip: 'Access to all vehicle data requests for a 7 day period',
          },
          {
            text: '2 dashboard apps',
          },
          {
            text: 'Batch requests',
            tooltip: 'Retrieve large amounts of vehicle data by HTTP requests',
          },
        ],
      },
    },
    {
      planName: 'Scale',
      price: {
        cost: 'Custom',
      },
      planSubtitle: '100 vehicles minimum',
      planHighlights: [
        'Unlimited vehicles',
        'Custom API call limit',
        'Tiered usage-based pricing',
        '2 applications',
        'Full access to simulated vehicles',
        '30 days of log data',
        'Smartcar Connect customization',
      ],
      planFeatures: {
        title: 'Everything in Build, plus:',
        features: [
          {
            text: 'Advanced connect flow',
            tooltip: 'Access to Brand Select and Single Select workflows',
          },
          {
            text: 'Logs exporting',
            tooltip: 'Ability to export all log and vehicle data ',
          },
          {
            text: '90-day request logs',
            tooltip: 'Access to all vehicle data requests for a 90-day period',
          },
          {
            text: '10 dashboard apps',
          },
          {
            text: 'Compatibility check',
            tooltip:
              'Check the brand compatibility of the vehicle by API before asking a user to connect their car',
          },
        ],
      },
    },
    {
      planName: 'Enterprise',
      price: {
        cost: 'Custom',
      },
      planSubtitle: '500 vehicles minimum',
      planHighlights: [
        'Up to 500 vehicles included',
        'Custom API call limit',
        'SLAs/Certifications',
        'Up to 50 applications',
        'Scheduled webhooks',
        'Full access to simulated vehicles',
        '365 days of log data',
        'Log filtering and exporting',
        'Smartcar Connect customization',
        'VIN-based compatibility API',
        'Dedicated Slack channel',
        'Managed onboarding',
      ],
      planFeatures: {
        title: 'Everything in Scale, plus:',
        features: [
          {
            text: 'Managed onboarding',
          },
          {
            text: 'Dedicated support',
            tooltip: 'Dedicated customer success manager for your account',
          },
          {
            text: '365-day request logs',
            tooltip: 'Access to all vehicle data requests for a 365-day period',
          },
          {
            text: 'SLAs (optional)',
            tooltip: 'Only available with minimum spend commitments',
          },
          {
            text: 'Unlimited dashboard apps',
          },
          {
            text: 'Access to test fleet',
            tooltip: 'Access to the Smartcar test fleet to test your vehicles',
          },
          {
            text: 'Priority response',
            tooltip: 'Response times to support tickets within a 1-hour window',
          },
          {
            text: 'Security audit',
            tooltip:
              'Smartcar will answer compliance questionnaires when asked',
          },
          {
            text: 'Event-based webhooks',
            tooltip: 'Webhooks which are triggered on a specific action',
          },
          {
            text: 'Phone support',
          },
        ],
      },
    },
  ],
  current: 'Current',
  buttonTexts: {
    downgrade: 'Downgrade',
    upgrade: 'Upgrade',
    startTrial: '15-days free',
    contactUs: 'Contact us',
    selected: 'Selected',
    current: 'Current',
    inTrial: 'In trial',
    addPaymentInfo: 'Add payment info',
  },
};

export default staticText;
