const staticText = {
  title: 'Invite members to your team',
  instructions: 'Invitations will expire 24 hours after being sent.',
  inviteButton: 'Invite',
  cancelButton: 'Cancel',
  emailVerificationRequired: 'In order to update your team,',
  rolesGatedText: 'Admin, editor, and viewer roles are only available on the Enterprise plan. Contact sales to learn more!',
  selectRole: 'Select role for members',
  appAccess: {
    label: 'Select applications',
    allApplications: 'All applications',
    allAppsAdminOnly: 'All applications (Admin only)',
    selectAccess: 'Select access level for members',
  },
  roleError: 'Please select a role from the options list',
  updateButton: 'Update',
  role: 'Role',
  accessLevel: 'Access Level',
};

export default staticText;
