import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UpdateUser from './UpdateUser';
import { actions as updateUserActions } from '../../../../../../../../services/auth/reducers';

const mapStateToProps = (state) => {
  return {
    ...state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  const {
    forgotPasswordRequest,
    resetErrors,
    sendVerificationEmailRequest,
    updateUserRequest,
  } = updateUserActions;

  return {
    actions: bindActionCreators({
      forgotPasswordRequest,
      resetErrors,
      sendVerificationEmailRequest,
      updateUserRequest,
    }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser);
