import _ from 'lodash';
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { bool, func, number, object, string } from 'prop-types';

import { ButtonContainer, CheckboxContainer, Form } from './styles';
import Checkbox from '../Checkbox';
import { staticText } from '../../localization/Application/Logs/filtersBar';

const FiltersForm = ({
  checkboxOptions,
  columns,
  title,
  updateCheckboxes,
  getLabel,
  sortOptions,
  hideAllButtons,
  maxNumSelected,
  setOpen,
  showDoneButton,
}) => {
  const numCheckboxes = Object.keys(checkboxOptions).length;

  const onChange = (e) => {
    const clonedCheckboxOptions = { ...checkboxOptions };
    if (!clonedCheckboxOptions[e.target.name]) {
      clonedCheckboxOptions[e.target.name] = true;
    } else {
      clonedCheckboxOptions[e.target.name] = false;
    }

    updateCheckboxes(clonedCheckboxOptions);
  };

  const clearAll = () => {
    const updatedCheckboxOptions = _.mapValues(checkboxOptions, () => false);
    updateCheckboxes(updatedCheckboxOptions);
  };

  const selectAll = () => {
    const updatedCheckboxOptions = _.mapValues(checkboxOptions, () => true);
    updateCheckboxes(updatedCheckboxOptions);
  };

  const cleanText = text => text.trim().toUpperCase().replace(/_/g, ' ');

  const options = Object.keys(checkboxOptions);
  if (sortOptions) {
    options.sort(sortOptions);
  }

  return (
    <Form>
      {title && <Typography id="filters-form-title" variant="h3">{title}</Typography>}
      <CheckboxContainer numCheckboxes={numCheckboxes} columns={columns}>
        {
          options.map((boxLabel) => {
            return (
              <Checkbox
                key={boxLabel}
                inputName={boxLabel}
                text={getLabel ? getLabel(boxLabel) : cleanText(boxLabel)}
                checked={checkboxOptions[boxLabel]}
                handleChange={onChange}
                wrapperClassName="flex-vertical-center m-b-lg compact"
                disabled={maxNumSelected > 2 && !checkboxOptions[boxLabel]}
                disabledCheckboxText={maxNumSelected > 2 && !checkboxOptions[boxLabel]}
              />
            );
          })
        }
      </CheckboxContainer>
      {
        !hideAllButtons &&
        (
          <ButtonContainer>
            <Button
              id="select-all"
              variant="outlined"
              color="primary"
              type="button"
              onClick={selectAll}
            >{staticText.selectAllCheckboxes}
            </Button>
            <Button
              id="clear-all"
              variant="outlined"
              color="primary"
              type="button"
              onClick={clearAll}
            >{staticText.clearAllCheckboxes}
            </Button>
          </ButtonContainer>
        )
      }
      {showDoneButton && (
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={() => setOpen(false)}>Done</Button>
        </Box>
      )}
    </Form>
  );
};

export default FiltersForm;

FiltersForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  checkboxOptions: object.isRequired,
  updateCheckboxes: func.isRequired,
  title: string.isRequired,
  columns: number,
  // gets label for value if they're different
  getLabel: func,
  sortOptions: func,
  hideAllButtons: bool,
  maxNumSelected: number,
  setOpen: func.isRequired,
  showDoneButton: bool,
};

FiltersForm.defaultProps = {
  columns: 3,
  maxNumSelected: 0,
  getLabel: null,
  sortOptions: null,
  hideAllButtons: false,
  showDoneButton: false,
};
