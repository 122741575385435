import { styled, Button, Typography } from '@mui/material';

export const EmailVerificationContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.warning.light,
  border: `1px solid ${theme.palette.warning.main}`,
  margin: '-1.5rem -1.5rem 0rem -1.5rem',
  padding: theme.spacing(3),
}));

export const EmailVerificationError = styled(Typography)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(1),
}));

export const EmailVerificationButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0),
  '&:hover': {
    backgroundColor: 'transparent',
  },
  marginTop: theme.spacing(1),
}));

export const UpdateUserForm = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(1),
  ':only-child': {
    marginTop: theme.spacing(0),
  },
}));
