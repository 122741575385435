import React from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import staticText from '../../localization/Legal/disclaimer';

const Disclaimer = () => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        margin: theme.spacing(1, 0),
        fontSize: '0.75rem',
        color: theme.palette.grey[600],
      }}
    >
      {staticText.legalDisclaimer}
    </Typography>
  );
};

export default Disclaimer;
