import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';

import image from '../../assets/images/platform-features.png';
import { Button, Container, Image, Link, Text } from './styles';

const NoResults = ({
  text,
  link,
  button,
  outlined,
}) => {
  const handleLinkClick = () => {
    if (link.handleClick) {
      link.handleClick();
    }

    reportToSegment(types.TRACK, eventNames.linkClicked, {
      section: 'article',
      style: 'cta primary',
      text: `[${link.trackingContext}] ${link.text}`,
      path: link.path,
    });
  };

  const handleButtonClick = () => {
    button.handleClick();

    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'action',
      text: `[${button.trackingContext}] ${button.text}`,
    });
  };

  return (
    <Container outlined={outlined} >
      <Image src={image} alt="astronaut checking his phone next to a car" />
      <Text>{text}</Text>
      {button &&
        <Button
          id="no-results-button"
          color="primary"
          onClick={handleButtonClick}
          variant="contained"
        >
          {button.text}
        </Button>
      }
      {link &&
        <Link
          id="no-results-link"
          href={link.href}
          onClick={handleLinkClick}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link.text}
        </Link>
      }
    </Container>
  );
};

export default NoResults;

NoResults.propTypes = {
  text: string.isRequired,
  link: shape({
    handleClick: func,
    href: string.isRequired,
    text: string.isRequired,
    // one word context
    trackingContext: string.isRequired,
  }),
  button: shape({
    handleClick: func.isRequired,
    text: string.isRequired,
    trackingContext: string.isRequired,
  }),
  outlined: bool,
};

NoResults.defaultProps = {
  button: null,
  link: null,
  outlined: true,
};
