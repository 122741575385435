import React from 'react';
import { func, string, object } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, useTheme } from '@mui/material';
import { withRouter } from 'react-router-dom';

import Modal from '../Modal';
import { Button, ButtonContainer, Image, StyledButton, Text, UpgradeTitle } from './styles';
import staticText from '../../localization/Components/upsellModal';
import image from '../../assets/images/free.png';

const handleUpgradeClick = ({
  feature, history, toggleModal,
}) => {
  const billingPath = '/team/billing';
  reportToSegment(types.TRACK, eventNames.linkClicked, {
    section: 'article',
    style: 'cta primary',
    text: `upsell (${feature})`,
    path: billingPath,
  });
  history.push(billingPath);
  toggleModal();
};

const UpsellModal = ({
  toggleModal, feature, history,
}) => {
  const theme = useTheme();
  const content = (
    <Box textAlign="center" marginTop={theme.spacing(1)} marginBottom={theme.spacing(3)}>
      <UpgradeTitle>{staticText.title}</UpgradeTitle>
      <Image src={image} alt="shooting star" />
      <Text>{staticText[feature].body}</Text>
      <ButtonContainer>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => handleUpgradeClick({
            feature, history, toggleModal,
          })}
          id="upgrade-now"
        >
          {staticText.upgrade}
        </StyledButton>
        <Button
          fontSize="1.125rem"
          onClick={toggleModal}
          id="decline"
          variant="outlined"
        >
          {staticText.decline}
        </Button>
      </ButtonContainer>
    </Box>
  );

  return <Modal toggleModal={toggleModal} content={content} />;
};

UpsellModal.propTypes = {
  toggleModal: func.isRequired,
  feature: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: object.isRequired,
};

export default withRouter(UpsellModal);
