const staticText = {
  title: 'Team up for speed',
  subtitle: 'Integrate with Smartcar faster by collaborating with others. Invite as many members as you want for free.',
  buttons: {
    sendInvite: 'Send invite',
    skip: 'Skip',
  },
};

export default staticText;
